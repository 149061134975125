import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components =>
import Wrapper from '../Utilities/Wrapper';

// Actions
import {modalForgot, requestPass} from './Actions';

/****************************************
    Return Render =>
****************************************/
class Forgot extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
				<Dialog
					fullScreen={classes}
					open={this.props.acceso.forgot}
					onClose={() => this.props.modalForgot(false)}
					aria-labelledby="responsive-dialog-title"
					className="mit-dialog"
				>
		            <div className={"mit-forms"}>
			            <form onSubmit={(e) => { this.props.requestPass(e); e.preventDefault();}}>
							<DialogTitle>
								Recuperación de contraseña
							</DialogTitle>
							<DialogContent className="dialog-content">
								<DialogContentText>
									<span>Ingresa tu cuenta de correo registrada en el sitio, te enviaremos las instrucciones para recuperar tus datos de acceso</span>
									<input autoComplete="off" style={{ width: '220px', display: 'block', margin: '20px auto 0px auto' }} type="text" name="email" placeholder="E-mail registrado en el sitio:"/>
								</DialogContentText>
							</DialogContent>
							<DialogActions className="dialog-actions">
								<Wrapper issubmiting={this.props.global.issubmiting}>
									<button type="submit" className={"cta green"}>
										<i className="fas fa-lock"></i> Recuperar mi cuenta
									</button>
								</Wrapper>
							</DialogActions>
						</form>
					</div>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        modalForgot,
        requestPass
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Forgot); 
