import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Router =>
import { BrowserRouter as NavLink, Link } from 'react-router-dom';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {logOut} from '../../Acceso/Actions';


// With Styles =>
const styles = theme => {
    return {
        logout: {
            backgroundColor: '#00381b',
            width: '40px',
            height:'40px',
            color: '#fff',
            fontSize:'14px',
            marginTop:'-5px'
        },
        chip: {
            backgroundColor: '#00a550',
            color: '#fff',
            border:'1px solid #fff',
            marginTop: '-8px !important'
        }
    };
};



/****************************************
    Return Render =>
****************************************/
class Header extends React.Component {

    isIn(){
        const { classes } = this.props;
        return <div style={{ marginTop: '55px', textAlign: 'right' }}>
            <Grid container spacing={8}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <Link to={"/perfil"} className={"cta green "+classes.chip}>Visita tu perfil</Link>
                </Grid>
                <Grid item lg={4} md={4}>
                    <Button onClick={() => this.props.logOut() } variant="fab" className={classes.logout}>
                        <i className="fas fa-power-off"></i>
                    </Button>
                </Grid>
            </Grid>
        </div>
    }

    isOut(){
        return (<Grid item xs={12}><Link to={"/acceso"} className="cta">ACCESO A USUARIOS</Link></Grid>)
    }


    componentWillMount(){
        console.log(this.props);
    }


    render() {
		//const { classes } = this.props;
		const vars = this.props.global.variables;
        const section = this.props.global.section;

        return (
	        <header>
	            <div className={"breakpoint"}>
	                <Grid container spacing={24}>
                        <Grid item md={2} sm={2}>
	                        <Link to={"/"} className="logo">
	                            <img src={"/img/general/logo-header-aniversario-mit-color.svg"} alt={"MIT Logo"}/>
	                        </Link>
	                    </Grid>
                        <Grid item md={10} sm={10}>
                            <Grid container>
                                <Grid item md={4} sm={6}>
                                    <a href={"mailto:"+vars.correo}>{ vars.correo }</a>
                                </Grid>
                                <Grid item md={2} sm={6}>
                                    <a className="tel" href={"tel:"+vars.telefono1}>Tel 1: <b>{vars.telefono1}</b></a>
                                </Grid>
                                <Grid item md={2} sm={6}>
                                    <a className="tel" href={"tel:"+vars.telefono2}>Tel 2: <b>{vars.telefono2}</b></a>
                                </Grid>
                                <Grid item md={4} sm={12} className={"follow-us"}>
                                    <span>Síguenos en:</span>
                                    <ul>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href={vars.facebook}><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href={vars.twitter}><i className="fab fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href={vars.instagram}><i className="fab fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" href={"https://wa.me/"+vars.whatsapp}><i className="fab fa-whatsapp"></i></a>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item md={9} sm={9}>
                                    <nav>
                                        <ul>
                                            <li><Link className={section === '' ? 'active' : ''} to={"/"}>INICIO</Link></li>
                                            <li><Link className={section === 'conocenos' ? 'active' : ''} to={"/conocenos"}>CONÓCENOS</Link></li>
                                            <li><Link className={section === 'agenda' ? 'active' : ''} to={"/agenda"}>PRÓXIMOS VIAJES</Link></li>
                                            <li><Link className={section === 'nuestros-destinos' ? 'active' : ''} to={"/nuestros-destinos"}>NUESTROS DESTINOS</Link></li>
                                            <li><Link className={section === 'blog' ? 'active' : ''} to={"/blog"}>BLOG</Link></li>
                                            <li><Link className={section === 'contacto' ? 'active' : ''} to={"/contacto"}>CONTACTO</Link></li>
                                        </ul>
                                    </nav>
                                </Grid>
                                <Grid item md={3} sm={9}>
                                    {/* Is In/Out */}
                                    {this.props.global.authorized ? (this.isIn()) : (this.isOut())}
                                </Grid>
                            </Grid>
                        </Grid>
	                </Grid>
	            </div>
	        </header>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        logOut
    }, dispatch);
}

export default compose(
	withStyles(styles),
    connect(mapStateToProps, matchDispatchToProps)
)(Header);