export const notifications = (state = {
	items: false,
	imgmodal: false,
	imgsrc: false,
}, action) => {
	switch(action.type)
	{
		case "SET-NOTIFICATIONS":
			state = {
				...state,
				items : action.payload
			};
		break;

		case "SET-MODAL-IMG":
			state = {
				...state,
				imgmodal : action.payload
			};
		break;		

		case "SET-IMG-SRC":
			state = {
				...state,
				imgsrc : action.payload
			};
		break;				

		default:
			return state;
	}

	return state
};