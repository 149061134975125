import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Link } from 'react-router-dom';

// Actions
import {getAgenda} from './Actions';


// With Styles =>
const styles = theme => {
    return {
        progress : {
            color: '#00a550',
            marginTop: '80px'
        }        
    };
};


/****************************************
    Return Render =>
****************************************/
class Agenda extends React.Component {

    componentWillMount(){
        var url = document.URL.split("/");
        var id  = url[url.length - 1];
        this.props.getAgenda(id);
        window.scrollTo(0,0);
    }


    render() {
        const { classes } = this.props;
        return (
            <div id="agenda" className="destino">
                <div className={"breakpoint"}>
                    {this.props.destinos.agenda ? (
                        <div>
                            <h1>{this.props.destinos.agenda.destino.name}</h1>
                            <h3>Próximas fechas</h3>                            
                            {this.props.destinos.agenda.events.map(item => {
                                return (
                                    <Grid container key={item.uuid} className={"event-item"}>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <div className="img-holder">
                                                <img src={item.img} alt={item.name} />
                                            </div>
                                        </Grid>
                                        <Grid item md={8} sm={12} xs={12} className={"text"}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <h2>{ item.name }</h2>
                                                </Grid>
                                                <Grid item md={5} sm={4} xs={12}>
                                                    <label>{ item.period }</label>
                                                </Grid>
                                                <Grid item md={4} sm={4} xs={12}>
                                                    <b>{ item.salida }</b>
                                                </Grid>
                                                <Grid item md={3} sm={4} xs={12}>
                                                    <span>Desde: <b>${ item.pack.price }</b></span>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {item.packages.map( pack => {
                                                        return(
                                                            <Grid key={pack.uuid} container className={"pack"}>
                                                                <Grid item md={2}>
                                                                    <b>{pack.price}</b>
                                                                </Grid>

                                                                <Grid item md={10}>
                                                                    <h3>{pack.name}</h3>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <p>{pack.description}</p>
                                                                </Grid>                                           
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="button-holder">
                                                        <Link to={`/agenda/${item.slug}`} className={"cta green"}> Detalles del viaje</Link>
                                                        <Link to={`/agenda/${item.slug}`} className={"cta gray"}> Precios y Promociones</Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}

                            {this.props.destinos.agenda.events.length === 0 ? (
                                <Grid container>
                                    <Grid item xs={12} style={{ color: "#b0b0b0" }}>
                                        <i className="fas fa-cogs" style={{ fontSize: "20px", marginRight: "20px"}}></i> Lo sentimos, no existen por el momento próximos eventos para este destino.
                                    </Grid>
                                </Grid>
                            ) : (null)}                            
                        </div>
                    ) : (
                        <Grid container justify="center">
                            <Grid item md={2}>
                                <CircularProgress className={classes.progress}/>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container align="right">
                        <Grid item xs={12}>
                            <Link to={"/nuestros-destinos"} className={"link-more"}>Ver todos los destinos</Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getAgenda
    }, dispatch);
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, matchDispatchToProps)
)(Agenda);