// API Call Wrapper:
export function API(url, method, body){
    var token = (localStorage.getItem('token')) ? localStorage.getItem('token') : 'no-token';

    var obj = {
        'method' : method,
        'headers': {
            'X-MIT-TOKEN' : token
        }
    }
 
    if(method === 'POST')
    obj["body"] = body;

    var request = new Request(url, obj);
    return fetch(request);
}




// UUID Generator:
export function UUID() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}



// Smooth Scrolling
export function windowScroll(top, left){
    window.scroll({ top: top, left: left, behavior: 'smooth' });
}