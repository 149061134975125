import React from 'react';
import compose from 'recompose/compose';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {selectSeat, seatForbidden} from './Actions';

// Componetns =>

/****************************************
    Return Render =>
****************************************/
class Seat extends React.Component {
    render() {
        const index    = this.props.index;
        const seat     = this.props.seat;
        const isactive = (this.props.isactive) ? 'active' : '';
        const isbooked = (this.props.isbooked) ? 'booked' : '';

        if(isbooked === 'booked' || isactive === 'active')
        {
            return (<button type="button" onClick={() => this.props.seatForbidden() } className={"asiento a"+seat+" "+isbooked+" "+isactive}>{seat}</button>)
        }
        else
        {
            return (<button type="button" onClick={() => this.props.selectSeat(index, seat) } className={"asiento a"+seat+" "+isbooked+" "+isactive}>{seat}</button>)
        }
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        selectSeat,
        seatForbidden
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Seat); 