 
// Inicializa las variables
export function initDepositVars() {
    return (dispatch, getState) => {
    	const state = getState();

    	// Slug
		var spl = document.URL.split('/');
		var slug = spl[spl.length-1];

    	var init = {
						thankyou: false,
						slug: slug,
						info: false,
						counter: 1, 		// <= The booking number
						total: '$0.00',
						abonado: '$0.00',
						pendiente: '$0.00',
						quantity: '',
						accepted: false,
						image: false,
						src: false,
						reference: '',
						comments: '',
						positions: ['','','','',''],
						split: {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},
						firstname : {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},
						lastname : {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},	
						email : {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},
						phone : {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},
						gender : {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},
						tshirt : {
							1 : '',
							2 : '',
							3 : '',
							4 : '',
							5 : ''
						},				
						bus  : {
							1 : false,
							2 : false,
							3 : false,
							4 : false,
							5 : false
						},
						pack : {
							1 : false,
							2 : false,
							3 : false,
							4 : false,
							5 : false
						},
						seat : {
							1 : false,
							2 : false,
							3 : false,
							4 : false,
							5 : false
						},
						add  : {
							1 : {1 : false,2 : false,3 : false,4 : false,5 : false},
							2 : {1 : false,2 : false,3 : false,4 : false,5 : false},
							3 : {1 : false,2 : false,3 : false,4 : false,5 : false},
							4 : {1 : false,2 : false,3 : false,4 : false,5 : false},
							5 : {1 : false,2 : false,3 : false,4 : false,5 : false}
						},
						cost : {
							1 : '$0.00',
							2 : '$0.00',
							3 : '$0.00',
							4 : '$0.00',
							5 : '$0.00'
						},
						abonos : {
							1 : '$0.00',
							2 : '$0.00',
							3 : '$0.00',
							4 : '$0.00',
							5 : '$0.00'
						},
						pedniente : {
							1 : '$0.00',
							2 : '$0.00',
							3 : '$0.00',
							4 : '$0.00',
							5 : '$0.00'
						}	
					}

		// Reset all deposit vars to its original state =>
		dispatch({
			type 	: "RESET-DEPOSIT-STATES", 
			payload	: init
		});

		// Asigna los datos del usuario a la primera persona =>
		var fields = ['firstname','lastname','email','phone','gender','tshirt'];
		var user = state.global.user;

		fields.map(field => {
	    	var payload = {index: 1, field: field, value: user[field]};
			dispatch({
				type 	: "UPDATE-BOOKING-FIELD", 
				payload	: payload
			});  
			return true;
		});
    }
}




// Obtiene información del cliente y el evento al que intenta abonar =>
export function eventDepositsInfo(slug) {
    return (dispatch, getState) => {
    	const state = getState(); 	
    	const request = fetch(`${state.global.API}eventDepositsInfo/${localStorage.getItem("token")}/${slug}`);
	    request.then(res => res.json()).then(info => {
			dispatch({
				type 	: "SET-EVENT-DEPOSITS-INFO", 
				payload	: info
			});			
	    })    	
    }
}



// Indica el número de personas que reservan =>
export function bookingNumber(ele) {
	var num = ele.target.value;
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-BOOKING-NUMBER", 
			payload	: num
		});   	
    }
}




// Indica el camión activo de un usuario =>
export function setActiveBus(index, id){
    return (dispatch) => {
    	var payload = {index: index, id: id};
		dispatch({
			type 	: "DEPOSIT-SET-ACTIVE-BUS", 
			payload	: payload
		});				
    }
}



// Actualiza el nombre de un usuario =>
export function updateField(e, field, index){
	var value = e.target.value;
    return (dispatch, getState) => {
    	var payload = {index: index, field: field, value: value};
		dispatch({
			type 	: "UPDATE-BOOKING-FIELD", 
			payload	: payload
		});  	
    }
}



// Indica el asiento activo de un usuario =>
export function selectSeat(index, seat){
    return (dispatch, getState) => {
    	var state = getState(); 
    	var bus = state.abono.bus[index];
    	seat = bus+'-'+seat;
    	var payload = {index: index, seat: seat};
		dispatch({
			type 	: "SET-BOOKING-ACTIVE-SEAT", 
			payload	: payload
		});  	
    }
}




// Indica el paquete seleccionado de un usuario =>
// Index => La posición del paquete
// Checked => Si el checkbos está en true/false
// Id => El id del paquete
export function updatePack(index, checkbox, id){
    return (dispatch, getState) => {
		
    	//var checked = checkbox.checked;
    	//var pack 	= (checked) ? id : false;
		var payload = {index: index, pack: id};

		dispatch({
			type 	: "SET-BOOKING-PACK", 
			payload	: payload
		});

		dispatch(recalculateCosts());
    }
}



// Indica los adicionales seleccionados de un usuario =>
// Persona => La posición de la persona
// Index => La posición del adicional
// Checked => Si el checkbos está en true/false
// Id => El id del adicional
export function updateAdd(persona, index, checkbox, id){
    return (dispatch) => {
    	var checked = checkbox.checked;
    	var label   = checkbox.parentNode;
    	var add 	= (checked) ? id : false;
    	var payload = {persona: persona, index: index, add: add};

    	if(checked)
    	{
    		label.classList.add("checked");
    	}
    	else
    	{
    		label.classList.remove("checked");
    	}

		dispatch({
			type 	: "SET-BOOKING-ADD", 
			payload	: payload
		});
		dispatch(recalculateCosts());
    }
}




// Muestra el Snackbar Error si el adicional no está habilitado:
export function addDisabled(){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SNACK-BAR", 
			payload	: true
		});

		dispatch({
			type 	: "SNACK-BAR-MESSAGE",
			payload	: "Sólo se pueden seleccionar adicionales que pertenezcan al paquete elegido.", 
		});		
    }
}



// Recalcula los costos cuando un paquete o adicional son seleccionados:
export function recalculateCosts(){
    return (dispatch, getState) => {
    	var state = getState();

		var request  = new Request(`${state.global.API}recalculateCosts`, {
			method: 'POST',
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
			body: JSON.stringify(state.abono)
		});
		fetch(request).then(res => res.json()).then(json => {

			json.costs.map((cost, index) => {
		    	var payload = {index: (index+1), cost: cost};
				dispatch({
					type 	: "SET-BOOKING-COST", 
					payload	: payload
                });
                return true;
			});

			dispatch({
				type 	: "SET-BOOKING-TOTAL", 
				payload	: json.total
			});

		});
    }
}


// Muestra un error si el asiento está ocupado
export function seatForbidden(){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SNACK-BAR", 
			payload	: true
		});

		dispatch({
			type 	: "SNACK-BAR-MESSAGE",
			payload	: "Lo sentimos, este asiento ya se encuentra ocupado por alguien más.", 
		});		
    }
}


// Procesa & Valida el depósito =>
export function saveDeposit(){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});
    	var state = getState();
		var request  = new Request(`${state.global.API}saveDeposit/${localStorage.getItem("token")}/${state.abono.slug}`, {
			method: 'POST',
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
			body: JSON.stringify(state.abono)
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0]
				});	
			}
			else
			{
				dispatch({
					type 	: "SET-DEPOSIT-THANKYOU", 
					payload	: true
				});   
				window.scrollTo(0, 0);
			}
			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
		});
    }
}



// Image Loader:
export function imageLoader(tgt){
    return (dispatch, getState) => {
        //var tgt     = evt.target || window.event.srcElement;
        var files   = tgt.files;

        // Bytes =>
        var bytes = files[0].size;

        // Formula
		function formatBytes(bytes) {
			var obj = {};

		    if(bytes < 1024)
		    obj = {"unit":"Bytes", "value" : bytes};
		    else if(bytes < 1048576) 
		    obj = {"unit":"KB", "value" : (bytes / 1024).toFixed(3)};
		    else if(bytes < 1073741824)
		    obj = {"unit":"MB", "value" : (bytes / 1048576).toFixed(3)};
		    else 
		    obj = {"unit":"GB", "value" : (bytes / 1073741824).toFixed(3)};

			return obj;
		};

		let imgobj = formatBytes(bytes);

		if(imgobj.unit === 'MB' && imgobj.value >= 5)
		{
			dispatch({
				type 	: "SNACK-BAR", 
				payload	: true
			});
			dispatch({
				type 	: "SNACK-BAR-MESSAGE", 
				payload	: "El archivo que intentas adjuntar es demasiado pesado, tú imagen de depósito debe pesar menos de 5 MB"
			});	
		}
		else
		{
	        if(FileReader && files && files.length)
	        {
	            var fr = new FileReader();
	            fr.onload = function (){
					dispatch({
						type 	: "SET-BOOKING-IMAGE", 
						payload	: true
					});

					dispatch({
						type 	: "SET-BOOKING-SRC", 
						payload	: fr.result
					});
	            }
	            fr.readAsDataURL(files[0]);
	        }
		}

    }
}



// Actualiza los comentarios =>
export function updateComments(value){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-BOOKING-COMMENTS", 
			payload	: value
		});
    }
}


// Actualiza la referencia del depósito =>
export function updateReference(value){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-BOOKING-REFERENCE", 
			payload	: value
		});
    }
}


// Actualiza la cantidad del depósito
export function updateQty(value){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-BOOKING-QTY", 
			payload	: value
		});
    }
}


// Terms & Conditions
export function updateTerms(e){
	var checked = e.target.checked;
    var label   = e.target.parentNode;
	if(checked)
	{
		label.classList.add("checked");
	}
	else
	{
		label.classList.remove("checked");
	}

    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-TERMS-AND-CONDITIONS", 
			payload	: checked
		});
    }
}


// Procesa los nuevos depósitos
export function anotherDeposit(){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});
    	var state = getState();
		var request  = new Request(`${state.global.API}anotherDeposit/${localStorage.getItem("token")}/${state.abono.slug}`, {
			method: 'POST',
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
			body: JSON.stringify(state.abono)
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0]
				});	
			}
			else
			{
				dispatch({
					type 	: "SET-DEPOSIT-THANKYOU", 
					payload	: true
				});
				// Vuelve a obtener la información del depósito:
				window.scrollTo(0, 0);
			}
			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
		});
    }
}



// Indica el número de personas que reservan =>
export function setTravellers() {
    return (dispatch, getState) => {
		var state = getState();
		if(state.abono.info)
		{
			dispatch({
				type 	: "SET-BOOKING-NUMBER", 
				payload	: state.abono.info.travellers.length
			}); 
		}  	
    }
}


// Cierra el mensaje de agradecimiento =>
export function closeThankyou() {
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-DEPOSIT-THANKYOU", 
			payload	: false
		});   	
    }
}



// Expand/Collapse Resume
export function setResume() {
    return (dispatch, getState) => {
    	var state = getState();
    	var status= (state.abono.resume === '') ? 'expanded' : '';
		dispatch({
			type 	: "SET-RESUME", 
			payload	: status
		});   	
    }
}
