import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {closeModal} from '../../Utilities/Actions';

/****************************************
    Return Render =>
****************************************/
class PasswordUpdated extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
				<Dialog
					fullScreen={classes}
					open={this.props.global.passwordupdated}
					onClose={() => this.props.closeModal("SET-DIALOG-PASSWORD-UPDATED")}
					aria-labelledby="responsive-dialog-title"
					className="mit-dialog success"
				>
					<DialogTitle>Contrsañe actualizada</DialogTitle>
					<DialogContent className="dialog-content">
						<i className="far fa-check-circle"></i>
						<DialogContentText>
							Tu contraseña ha sido actualizada exitósamente. Recuerda iniciar sesión con tu nueva contraseña la siguiente vez que nos visites.
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<button type="button" onClick={() => this.props.closeModal("SET-DIALOG-PASSWORD-UPDATED") } className={"cta gray"}>Ok, cerrar.</button>
					</DialogActions>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        closeModal
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(PasswordUpdated); 