import React from 'react';
import compose from 'recompose/compose';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material Ui =>
import Grid from '@material-ui/core/Grid';

// Routing =>
import { Link, NavLink } from 'react-router-dom';

// Actions =>
import {getCounters} from "./Actions";

// Componetns =>

/****************************************
    Return Render =>
****************************************/
class LeftNav extends React.Component {

    componentWillMount(){
        this.props.getCounters();
    }



    render() {
        const counters = this.props.global.counters;
        return (
            <Grid item md={3} id={"right-nav"}>
                <nav>
                    <Link to={"/"} id="logo">
                        <img src="/img/general/logo.png" alt="Mar Iguana Tours"/>
                    </Link>
                    <ul>
                        <li>
                            <NavLink exact to={"/"}><i className="fas fa-angle-double-left"></i> REGRESAR AL SITIO</NavLink>
                        </li>
                        <li>
                            <NavLink exact to={"/perfil"}><i className="fas fa-home"></i> INICIO <span>({ counters.notifications || "0" })</span></NavLink>
                        </li>
                        <li>
                            <NavLink exact to={"/perfil/mis-viajes"}><i className="fas fa-bus"></i> MIS PRÓXIMOS VIAJES <span>({counters.upcoming || "0"})</span></NavLink>
                        </li>
                        <li>
                            <NavLink exact to={"/perfil/historial"}><i className="fas fa-history"></i> HISTORIAL DE VIAJES <span>({counters.historic || "0"})</span></NavLink>
                        </li>
                        <li>
                            <NavLink exact to={"/perfil/mi-informacion"}><i className="fas fa-user"></i> MI PERFIL</NavLink>
                        </li>                                                                                                                                                                
                    </ul>
                </nav>
            </Grid>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getCounters
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(LeftNav); 