export function logIn(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-LOGING", 
			payload	: true
		});

		var state    = getState();
		var formData = new FormData(e.target);
		var request  = new Request(`${state.global.API}logIn`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0] // Mostramos el primero error en el nackbar
				});				
				dispatch({
					type 	: "SET-LOGING", 
					payload	: false
				});
			}
			else
			{
				// Welcome User =>
				localStorage.setItem('token', json.token);
				localStorage.setItem('user', JSON.stringify(json.user));

				dispatch({
					type 	: "SET-AUTH", 
					payload	: true
				});

				dispatch({
					type 	: "SET-USER", 
					payload	: json.user
				});	

				dispatch({
					type 	: "MODAL-WELCOME", 
					payload	: true
				});

				dispatch({
					type 	: "SET-LOGING", 
					payload	: false
				});

				// Handle redirect:
				var redirectTo = (localStorage.getItem("intendedUrl")) ? localStorage.getItem("intendedUrl") : "/perfil";
				localStorage.removeItem("intendedUrl");
				window.location.href = redirectTo;		
				
				// Asegura mostrar el mensaje de bienvenida:
				localStorage.setItem("welcome", true);
			}
		});
	};
}


export function logOut(){
	return (dispatch) => {
		dispatch({
			type 	: "SET-USER", 
			payload	: false
		});
		dispatch({
			type 	: "SET-AUTH", 
			payload	: false
		});		

		dispatch({
			type 	: "IS-LOGGED-OUT", 
			payload	: true
		});

		localStorage.removeItem('token');
        localStorage.removeItem('user');

        document.location.href = "/";
	}
}



export function notAuthorized(){
	return (dispatch, getState) => {
		dispatch({
			type 	: "MODAL-AUTORIZED", 
			payload	: true
		});		
	}
}



export function modalForgot(bool){
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-MODAL-FORGOT", 
			payload	: bool
		});		
	}
}


export function modalPasswordSent(bool){
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-PASSWORD-SENT", 
			payload	: bool 
		});	
	}
}



export function requestPass(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});

		var state    = getState();
		var formData = new FormData(e.target);
		var request  = new Request(`${state.global.API}requestPass`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.error.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.error[0]
				});				
				dispatch({
					type 	: "SET-SUBMITING", 
					payload	: false
				});
			}
			else
			{
				dispatch({
					type 	: "SET-MODAL-FORGOT", 
					payload	: false
				});	

				dispatch({
					type 	: "SET-SUBMITING", 
					payload	: false
				});

				dispatch({
					type 	: "SET-PASSWORD-SENT", 
					payload	: true 
				});
			}
		});
	};
}




// Procesa y valida el formulario de registro =>
export function formRegister(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});
		var state    = getState();
		var formData = new FormData(e.target);
		var request  = new Request(`${state.global.API}formRegister`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0] // Mostramos el primero error en el snackbar
				});				
			}
			else
			{
				// Registrado!
				dispatch(modalRegistered());
			}

			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});

		});
	};
}


// Modal de usuario registrado
export function modalRegistered(){
	return (dispatch, getState) => {
		var state = getState();
		var bool = state.acceso.registered;
		dispatch({
			type 	: "SET-MODAL-REGISTERED", 
			payload	: !bool 
		});	
	}
}
