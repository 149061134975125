import React from 'react';
import compose from 'recompose/compose';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

// Actions
import {dialogPassword } from '../../Utilities/Actions';
import {validatePassword, setPassword } from './Actions';

// Components:
import Wrapper from '../../Utilities/Wrapper';


class Password extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.global.password}
                onClose={() => this.props.dialogPassword(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Cambiar contraseña"}</DialogTitle>
                <DialogContent style={{ fontSize:'12px'}}>
        
                    <DialogContentText>
                        {/* Contraseña actual */}
                        {!this.props.global.changePassword ? (
                            <form className="mit-forms" onSubmit={(e) => {e.preventDefault(); this.props.validatePassword(e); }} style={{ marginTop: "0px"}}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <label>Para permitir el cambio de contraseña, es necesario que ingreses tu contraseña actual:</label>
                                        <input type="password" autocomplete="new-password" name="password" placeholder="Escribe tu contraseña actual:"/>
                                    </Grid>
                                    <Grid item xs={12} align="right">
                                        <Wrapper>
                                            <Button type="submit" className="cta black">Validar mi contraseña</Button>
                                        </Wrapper>
                                    </Grid>
                                </Grid>
                            </form>
                        ) : (
                            <form className="mit-forms" onSubmit={(e) => {e.preventDefault(); this.props.setPassword(e); }} style={{ marginTop: "0px"}}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <label><i className="fas fa-key" style={{ color: "green" }}></i> Nueva contraseña:</label>
                                        <input type="password" autocomplete="new-password" name="password" placeholder="Nueva contraseña:"/>
                                    </Grid>
                                    <Grid item xs={12} align="right">
                                        <Wrapper>
                                            <Button type="submit" className="cta black">Aplicar nueva contraseña</Button>
                                        </Wrapper>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        dialogPassword,
        validatePassword,
        setPassword
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Password); 