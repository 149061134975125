import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {
    bookingNumber, 
    updateField, 
    updatePack, 
    addDisabled, 
    updateAdd,  
    imageLoader, 
    updateComments, 
    updateQty, 
    updateReference
} from './Actions';

// Componetns =>
import Btn from './Btn';
import Bus from './Bus';
import Resume from './Resume';

/****************************************
    Return Render =>
****************************************/
class First extends React.Component {

    constructor(props) {
        super(props);
        this.state = { cssfixed:''};
        this.handleScroll = this.handleScroll.bind(this);
    }


    // Will Mount =>
    componentWillMount(){
        window.addEventListener('scroll', this.handleScroll);
        return false;
    }


    // Formulario para el registo de acompañantes
    preRegister(index){
        if(index > 1)
        {    
            return (
                <div className="mit-forms" key={"mit-forms-"+index}>
                    <Grid container spacing={24}>
                        <Grid item md={6} sm={6} xs={12}>
                            <input type="text" onChange={(e) => this.props.updateField(e, 'firstname', index) } defaultValue={this.props.abono.firstname[index]} placeholder="Nombre:"/>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <input type="text" onChange={(e) => this.props.updateField(e, 'lastname', index) } defaultValue={this.props.abono.lastname[index]} placeholder="Apellidos:"/>
                        </Grid>                        
                        <Grid item md={6} sm={6} xs={12}>
                            <input type="text" onChange={(e) => this.props.updateField(e, 'email', index) } defaultValue={this.props.abono.email[index]} placeholder="E-mail:"/>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <input type="text" onChange={(e) => this.props.updateField(e, 'phone', index) } defaultValue={this.props.abono.phone[index]} placeholder="Teléfono/Celular:"/>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <select onChange={(e) => this.props.updateField(e, 'gender', index) } defaultValue={this.props.abono.gender[index]}>
                                <option value="">&mdash;género&mdash;</option>
                                <option value="Masculino">Masculino</option>
                                <option value="Femenino">Femenino</option>
                            </select>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <select onChange={(e) => this.props.updateField(e, 'tshirt', index) } defaultValue={this.props.abono.tshirt[index]}>
                                <option value="">— talla —</option>
                                <optgroup label="Adulto">
                                    <option value="Chica">Chica</option>
                                    <option value="Mediana">Mediana</option>
                                    <option value="Grande">Grande</option>
                                    <option value="Extra Grande">Extra Grande</option>
                                </optgroup>
                                <optgroup label="Infantil">
                                    <option value="Inf. Chica">Chica</option>
                                    <option value="Inf. Mediana">Mediana</option>
                                    <option value="Inf. Grande">Grande</option>
                                </optgroup>
                            </select>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }



    /* Adicionales de un paquete */
    rowsAdditionals(id, index){
        var rows = [];
        this.props.abono.info.packages.forEach(pack => {
            if(pack.id === id)
            {
                pack.additionals.forEach((add, i) => {
                    if(i === 0)
                    {
                        rows.push(
                            <Grid container key={"add-title-row"+i}>
                                <Grid item sm={1} xs={false}></Grid>
                                <Grid item sm={11} xs={12}>
                                    <h3>Adicionales:</h3>
                                </Grid>
                            </Grid>
                        );
                    }
                    const checkbox = (this.props.abono.pack[index] !== add.pack) ? 
                                        <label className="disabled has-check" onClick={() => this.props.addDisabled() }><input type="checkbox" defaultChecked={false} disabled={true}  id={"addon-"+add.id} /> {add.name}</label> 
                                        : 
                                        <label className="has-check"><input type="checkbox" id={"addon-"+add.id} onChange={(e) => this.props.updateAdd(index, (i + 1), e.target, add.id) } /> {add.name}</label>;
                   
                    const button   = (this.props.abono.pack[index] !== add.pack) ? <label className="cta gray" onClick={() => this.props.addDisabled() }>${add.price}</label> : <label className="cta gray" htmlFor={"addon-"+add.id}>${add.price}</label>;
                    rows.push(
                        <Grid container key={"row-add-"+add.id}>
                            <Grid item sm={1} xs={false}></Grid>
                            <Grid item sm={9} xs={12}>
                                {checkbox}
                            </Grid>
                            <Grid item sm={2} xs={12} align="right">
                                {button}
                            </Grid>
                        </Grid>
                    );

                })
            }
        })
        return rows;
    }



    // Paquetes 
    rowsPackages(index){
        var rows = [];
         this.props.abono.info.packages.forEach(pack => {
            var chkClass = (parseInt(this.props.abono.pack[index]) === parseInt(pack.id)) ? "has-check checked" : "has-check";  
            rows.push(   
                <div key={"row-pack-"+pack.id+"-"+index}>                                       
                    <Grid container className="packages">
                        <Grid item md={10} xs={12}>
                            <label className={chkClass}><input type="checkbox" id={"pack-"+pack.id} onChange={(e) => this.props.updatePack(index, e.target, pack.id) }/> {pack.name}</label>
                        </Grid>
                        <Grid item md={2} xs={12} align="right"><label className="cta green" htmlFor={"pack-"+pack.id}>${pack.fprice}</label></Grid>                                    
                    </Grid>
                    {/* Adicionales */}
                    { this.rowsAdditionals(pack.id, index) }
                </div>
            );
        })
        return rows;
    }



    // Bloques de Configuración 
    configBlocks(){
        let blocks = [];
        for(var i = 1; i <= this.props.abono.counter; i++)
        {
            let title = (i === 1) ? 'Tu elección de paquete:' : 'Persona '+i+':';
            let seat_instruction = (i === 1) ? 'Selecciona tu asiento:' : 'Selecciona el asiento de esta persona';
            let buses = this.props.abono.info.buses;
            blocks.push(
                <div key={"config-block-"+i} className="rounded-box" style={{ marginBottom:'30px'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <h3>{title}</h3>
                        </Grid>
                    </Grid>

                    {/* Pre-Registro */}                               
                    { this.preRegister(i) }
                    
                    {/* Paquetes */}
                    { this.rowsPackages(i) }

                    {/* Camiones */}
                    <Grid container>
                        <Grid item xs={12}>
                            <h3>{seat_instruction}</h3>
                            <small>El modelo del camión es ilustrativo, y puedo variar al momento del viaje.</small>
                        </Grid>
                        <div className="buses-holder">
                            <Grid item xs={12}>
                                {/* Botones */}
                                {buses.map((bus) => {
                                    return (<Btn key={"btn-bus-"+i+"-"+bus.id} index={i} bus={bus} />)
                                })}
                            </Grid>
                            {/* Bus Layout */}
                            {buses.map((bus) => {
                                return(<Bus key={"layout-bus-"+i+"-"+bus.id} index={i} bus={bus} userseat={false} />)
                            })}  

                        </div>
                    </Grid>
                </div>
            );
        }
        return blocks;
    }




    // Area de repartición de Abono =>
    splitDepositArea(){
        if(this.props.abono.counter > 1)
        {
            return(
                <div>
                    {/* Yellow Warning */}
                    <div className="yellow-ribbon">
                        <div className="box">
                            <p><b>Nota:</b> Favor de dividir su depósito entre las personas que van a reservar el viaje, la cantidad total a ingresar debe coincidir con su depósito o transferencia.</p>
                            <img src="/img/general/pointing-arrow-big.png" alt={"pointer"} />
                        </div>
                    </div>
                    {/* Split them all!! */}
                    <div className="split-deposit">
                        <div className="mit-forms">
                            <Grid container className="th" spacing={16}>
                                <Grid item md={4}>Cantidad a dividir: <b>${this.props.abono.quantity}</b></Grid>
                                <Grid item md={3}><strong>Compra total</strong></Grid>
                                <Grid item md={3}>Asignar abono:</Grid>
                                <Grid item md={2} align="right">Adeudo:</Grid>
                            </Grid>
                            {/* División del Depósito */}
                            {this.props.abono.positions.map((item, index) => {
                                index++;
                                if(index <= this.props.abono.counter)
                                {
                                    return(<Grid key={"split-deposit"+index} container className={"td"} spacing={16}>
                                        <Grid item md={4}>
                                            <strong>Persona {index}</strong>
                                            <em>{this.props.abono.firstname[index]+' '+this.props.abono.lastname[index]}</em>
                                        </Grid>
                                        <Grid item md={3}>
                                            <a className="cta green">{this.props.abono.cost[index]}</a>
                                        </Grid>
                                        <Grid item md={3}>
                                            <input type="text"  onChange={(e) => this.props.updateField(e, 'split', index) } defaultValue={this.props.abono.split[index]} placeholder={"Cantidad:"} />
                                        </Grid>
                                        <Grid item md={2} align="right">
                                            <b>{this.props.abono.cost[index]}</b>
                                        </Grid>
                                    </Grid>); 
                                }
                                else
                                {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    }



    render() {
        const info = this.props.abono.info;
        if(info === false)
        {
            return(
                <Grid container justify="center">
                    <Grid item xs={12} align="center">
                        <CircularProgress style={{ color: "green" }} thickness={1.5}/>
                    </Grid>
                </Grid>)
        }
        else
        {
            return (
                <div id="abono">
                    <Grid container>
                        <Grid item md={4} sm={12} xs={12}>
                            <h1>Registrar abono</h1>
                        </Grid>
                        <Grid item md={8}  sm={12} xs={12} align="right">
                            <h2>{ info.event.name }</h2>
                            <b>{ info.event.period }</b>
                        </Grid>                    
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <div className="yellow-box">
                                <p>Para hacer válido tu registro, es necesario que nos hagas llegar el comprobante de tu pago así como algunos datos adicionales. 
                                Una vez enviados, <b>mar-iguanatours confirmará tu pago en un plazo no mayor a 48 horas, con lo que quedará apartado tu lugar</b>.</p>
                                <p><b>Listo, ¡a viajar!</b></p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={24}>
                        <Grid item lg={8} md={12} sm={12} xs={12}>

                            {/* Step 1 */}
                            <section>
                                <h2><big>1</big> Elige el número de reservaciones a comprar</h2>
                                <div className="rounded-box" style={{ padding: '5px 20px'}}>
                                    <div className="mit-forms">
                                        <Grid container spacing={24}>
                                            <Grid item md={2}>
                                                <select onChange={(e) => this.props.bookingNumber(e) }>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </Grid>
                                            <Grid item md={10}>
                                                <label>Reservaciones (personas)</label>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </section>

                            {/* Step 2 */}
                            <section>
                                <h2><big>2</big> Elección de paquetes:</h2>
                                {this.configBlocks()}
                            </section>

                            {/* $ Abono */}
                            <section>
                                <h2><big>3</big> Enviar notificación de abono:</h2>
                                <div className="rounded-box">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3>Información del abono</h3>
                                        </Grid>
                                    </Grid>
                                    <div className="mit-forms">
                                        <Grid container spacing={24}>
                                            <Grid item md={6} sm={6} xs={12}>
                                                <select onChange={(e) => this.props.updateReference(e.target.value)} defaultValue={this.props.abono.reference}>
                                                    <option value="">&mdash;Tipo&mdash;</option>
                                                    <option>Deposito</option>
                                                    <option>Transferencia</option>
                                                    <option>Cheque</option>
                                                </select>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12}>
                                                <input type="text" name="quantity" onChange={(e) => this.props.updateQty(e.target.value) } defaultValue={this.props.abono.quantity} placeholder="Cantidad:"/>
                                            </Grid>    
                                            <Grid item xs={12}>
                                                <label className="img-loader-holder">
                                                    {this.props.abono.image ? undefined : <span><i className="fa fa-camera" aria-hidden="true"></i></span>}
                                                    {this.props.abono.image ? <img src={this.props.abono.src} alt={"Imagen de abono"} /> : undefined }
                                                    <input type="file" onChange={(e) => this.props.imageLoader(e.target) } name="deposit" className="img-loader" accept="image/*"/>
                                                </label>
                                                <small>Sólo archivos: jpg, jpeg, gif ó png. Máximo 5MB</small>
                                            </Grid>   
                                            <Grid item xs={12}>
                                                <textarea name="comments" onChange={(e) => this.props.updateComments(e.target.value) } defaultValue={this.props.abono.comments} placeholder="Comentarios adicionales:" />
                                            </Grid>                                                             
                                                
                                            <Grid item xs={12} className="disclaimer">
                                                Si estás comprando una habitación doble o triple, favor de incluir los nombres de las personas con las que te hospedarás.
                                            </Grid>                                          
                                        </Grid>
                                    </div>
                                    {/* Area de depósito dividido */}
                                    { this.splitDepositArea() }                                  
                                </div>
                            </section>
                        </Grid>

                        {/* Resume */}
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Resume/>
                        </Grid>
                    </Grid>
                </div>
            )
        }   
    }



    // Resume Position Fixed vs Inherit
    handleScroll() {
        let cssfixed = '';
        if(document.documentElement.scrollTop > 337)
        {
            cssfixed = 'fixed';
        }
        if(this.state.cssfixed !== cssfixed)
        {
            this.setState({'cssfixed' : cssfixed});
        }
    }



}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        bookingNumber,
        updateField,
        updatePack,
        addDisabled,
        updateAdd,
        imageLoader,
        updateComments,
        updateQty,
        updateReference
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(First); 