import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Link } from 'react-router-dom';

// Actions
import {getEvents} from './Actions';

/****************************************
    Return Render =>
****************************************/
class Agenda extends React.Component {

    componentDidMount(){
        this.props.getEvents();
    }


    render() {
        return (
            <div id="agenda">
                <div className={"breakpoint"}>
                    <h1>{(document.location.pathname === '/') ? 'Agencia de tours y viajes saliendo de Guadalajara' : 'Próximos Viajes'} </h1>
                    {this.props.agenda.events ? (
                        this.props.agenda.events.map(item => {
                            var img = { backgroundImage: 'url("'+item.img+'")'};
                            img["backgroundPosition"] = (item.img === '/img/general/placeholder.png') ? "center center" : "top left";
                            return (
                                <Grid container key={item.uuid} className={"event-item"}>
                                    <Grid item md={4} sm={12} xs={12}>
                                        <Link to={`/agenda/${item.slug}`} className="img-holder" style={img} />
                                    </Grid>
                                    <Grid item md={8} sm={12} xs={12} className={"text"}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h2>{ item.name }</h2>
                                            </Grid>
                                            <Grid item md={5} sm={4} xs={12}>
                                                <label>{ item.period }</label>
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12}>
                                                <b>{ item.salida }</b>
                                            </Grid>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <span>Desde: <b>${ item.pack.price }</b></span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {item.packages.map( pack => {
                                                    return(
                                                        <Grid key={pack.uuid} container className={"pack"}>
                                                            <Grid item md={2} sm={2} xs={12}>
                                                                <b>{pack.price}</b>
                                                            </Grid>

                                                            <Grid item md={10} sm={10} xs={12}>
                                                                <h3>{pack.name}</h3>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <p>{pack.description}</p>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid item xs={12}>
                                               { item.available > 0 ?
                                                    <div className="button-holder">
                                                        <Link to={`/agenda/${item.slug}`} className={"cta green"}> Detalles del viaje</Link>
                                                        <Link to={`/agenda/${item.slug}`} className={"cta gray"}> Precios y Promociones</Link>
                                                        <br/>
                                                        {item.available <= 10 ? <small style={{ color: "red", paddingLeft: "10px", fontSize: 10 }}>Quedan {item.available} lugar(es) disponible(s)</small> : <small style={{ color: "#a19d00", paddingLeft: "10px", fontSize: 10 }}>Quedan pocos lugares disponibles</small> }
                                                    </div>
                                                :
                                                    <small style={{ color: "red", paddingLeft: "10px", fontSize: 10 }}>Lugares agotados.</small>
                                               }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid container>
                            <Grid item xs={12} align="center">
                                <CircularProgress style={{ color: '#00a550', marginTop: '80px'}} />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container align="right">
                        <Grid item xs={12}>
                            {/* <a href="" className={"link-more"}>Ver todos los eventos</a> */}
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getEvents
    }, dispatch);
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps)
)(Agenda);