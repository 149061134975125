// Incializa los states
export function setInitials(){
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-ACCOUNT-ACTIVATION", 
			payload	: "wait"
		});
		dispatch({
			type 	: "SET-ACCOUNT-ACTIVATION-DIALOG", 
			payload	: true
		});		
	}
}



// COnfirmación del Registro
export function getHome() {
    return (dispatch, getState) => {
    	const state = getState();
    	const request = fetch(`${state.global.API}getHome`);
	    request.then(res => res.json()).then(json => {
    		dispatch({
				type 	: "SET-HOME-BANNERS", 
				payload	: json.banners
			});		
	    })    	
    }
}


// COnfirmación del Registro
export function confirmRegister(crypt) {
    return (dispatch, getState) => {
    	const state = getState();
    	const request = fetch(`${state.global.API}confirmRegister/${crypt}`);
	    request.then(res => res.json()).then(json => {
    		dispatch({
				type 	: "SET-ACCOUNT-ACTIVATION", 
				payload	: json.status
			});		
	    })    	
    }
}



// Definición de la contraseña
export function definePassword(e) {
	var segents = document.URL.split('/');
	var crypt = segents[segents.length - 1];

	return (dispatch, getState) => {
		var state    = getState();
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});

		var form     = e.target;
		var formData = new FormData(form);
		var request  = new Request(`${state.global.API}definePassword/${crypt}`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0]
				});				
			}
			else
			{
				// Welcome User =>
				localStorage.setItem('token', json.token);
				localStorage.setItem('user', JSON.stringify(json.user));

				dispatch({
					type 	: "SET-AUTH", 
					payload	: true
				});

				dispatch({
					type 	: "SET-USER", 
					payload	: json.user
				});	

	    		dispatch({
					type 	: "SET-ACCOUNT-ACTIVATION", 
					payload	: json.status
				});	 
			}

			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
		});
	};
}












export function turnOffConfirmationDialog(){
	return (dispatch, getState) => {
		//var status = state.global.confirmando;
		dispatch({
			type 	: "SET-ACCOUNT-ACTIVATION-DIALOG", 
			payload	: false
		});		
		dispatch({
			type 	: "SET-ACCOUNT-ACTIVATION", 
			payload	: "wait"
		});	
	}
}