import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
//import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import SeatAssigned from './SeatAssigned';

/****************************************
    Return Render =>
****************************************/
class Wrapper extends React.Component {
    render() {
        return (
            <div id="misviajes">
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <h1>Mis próximos viajes</h1>
            	        {this.props.children}
                    </Grid>
                </Grid>
                {/* Modal dor Seat Assigned */}
                <SeatAssigned/>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // ???
    }, dispatch);
}
********************************************/



export default compose(
    connect(mapStateToProps, null)
)(Wrapper); 