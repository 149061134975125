import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


// Router =>
import { BrowserRouter as Link, NavLink } from 'react-router-dom'


// Actions
import {setInitials,confirmRegister,turnOffConfirmationDialog,definePassword} from './Actions';

// Components =>
import Wrapper from '../Utilities/Wrapper';


/****************************************
    Return Render =>
****************************************/
class Activating extends React.Component {


	componentWillMount(){
		var segents = document.URL.split('/');
		var crypt = segents[segents.length - 1];
		var section = segents[segents.length - 2];
		if(section && section === 'confirmar')
		{
			this.props.setInitials();
			this.props.confirmRegister(crypt);
		}
	}


    switchMessage(){
    	// Se muestra un bloque diferente de acuerdo al valor del state "confirmatio":
    	if(this.props.global.accountactivation === 'wait')
    	{
    		return(
	    		<div>
					<DialogTitle>
						Activación de la cuenta
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i style={{ color: '#111' }}className="far fa-clock"></i>
						<DialogContentText>
							<p>Espera un momento, estamos verificando la información.</p>
				            <div id="wrapper">
				            	<CircularProgress style={{ color: '#000' }} thickness={1.5}/>
				            </div>
						</DialogContentText>
					</DialogContent>
	    		</div>
    		);
    	}

    	if(this.props.global.accountactivation === 'error')
    	{
			return(
				<div>
					<DialogTitle>
						Activación de la cuenta
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i style={{ color: 'red' }}className="fas fa-times"></i>
						<DialogContentText>
							<p>Lo sentimos, no ha sido posible confirmar el registro, es posible que no cuentes con un link válido de confirmación.</p>
							<br/>
						</DialogContentText>
						<DialogActions className="dialog-actions">
							<Link to={"/"} className="cta black" onClick={() => this.props.turnOffConfirmationDialog() }><i style={{color:'#fff', fontSize:'15px'}} className="fas fa-undo-alt"></i> Volver al sitio</Link>
						</DialogActions>
					</DialogContent>
				</div>
			);
    	}

    	if(this.props.global.accountactivation === 'reqpass')
    	{
			return(
				<form className={"mit-forms"} onSubmit={(e) => { this.props.definePassword(e); e.preventDefault();}}>
					<DialogTitle>
						Asignación de Contraseña
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i style={{ color: '#111' }} className="fas fa-key"></i>
						<DialogContentText>
							<p>Asigna una nueva contraseña para tu cuenta, puedes elegir algo que sea fácil de recordar para tí.</p>
							<p><strong>Toma en cuenta que tu contraseña no podrá tener menos de 5 caracteres</strong></p>
							<input autoComplete="off" style={{ width: '220px', display: 'block', margin: '20px auto 0px auto' }} type="text" name="pass" placeholder="Tu contraseña:"/>
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<Wrapper issubmiting={this.props.global.issubmiting}>
							<button type="submit" className={"cta yellow"}>
								<i className="fas fa-key"></i> Asignar mi contraseña
							</button>
						</Wrapper>
					</DialogActions>
				</form>
			);
    	}

    	if(this.props.global.accountactivation === 'success')
    	{
			return(
	            <div>
					<DialogTitle>
						Te damos la bienvenida {this.props.global.user.firstname}
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i className="far fa-check-circle"></i>
						<DialogContentText>
							Has completado el proceso de confirmación o reactivación de tu cuenta <b>corréctamente</b>.
							<br/>
							¿Qué te gustaría hacer?
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<NavLink to={"/agenda"} className={"cta gray"} onClick={() => this.props.turnOffConfirmationDialog() }><i className="fas fa-calendar"></i> Visitar la agenda de eventos</NavLink>
						<NavLink to={"/perfil"} className={"cta green"} onClick={() => this.props.turnOffConfirmationDialog() }><i className="fas fa-user"></i> Visitar mi perfil</NavLink>
					</DialogActions>
	            </div>
			);
    	}    	
    }


    render() {
        return (
            <div>
				<Dialog className="mit-dialog" open={this.props.global.accountactivationdialog} onClose={() => {} } aria-labelledby="responsive-dialog-title">
					{ this.switchMessage() }
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        confirmRegister,
        turnOffConfirmationDialog,
        setInitials,
        definePassword
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Activating); 