export function sendContact(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});

		var state    = getState();
		var form     = e.target;
		var formData = new FormData(e.target);
		var request  = new Request(`${state.global.API}sendContact`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0] // Mostramos el primero error en el nackbar
				});				
			}
			else
			{
				form.reset();
				dispatch(thanksModal());
			}

			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
		});
	};
}




// Abre/Cierra el agradecimiento del contacto =>
export function thanksModal() {
	return (dispatch, getState) => {
		var state = getState();
		var bool  = state.contacto.thanks;
		dispatch({
			type 	: "SET-THANKS-CONTACT-MODAL", 
			payload	: !bool
		});
	};
}