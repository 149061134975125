import React from 'react';
import compose from 'recompose/compose';

// Redux =>
//import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Notificaciones from '../Notificaciones/Notificaciones';
//import Misviajes from '../Misviajes/Misviajes';

/****************************************
    Return Render =>
****************************************/
class Dashboard extends React.Component {
    render() {
        return (
            <div id="dashboard">
                {/* Notificaciones */}
                <Notificaciones/>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


export default compose(
    connect(mapStateToProps, null)
)(Dashboard);