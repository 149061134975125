import React from 'react';
import ReactDOM from 'react-dom';

// Service Worker
//import registerServiceWorker from './registerServiceWorker';

// Redux =>
import { createStore, applyMiddleware } from 'redux';

// Thunk =>
import thunk from 'redux-thunk';

// Reducers =>
import CombinedReducers from './combined-reducers';

// Provider =>
import {Provider} from 'react-redux';

// Css
import './index.css';
import './detail.css';

// Routing =>
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'


// Public Components =>
import PublicLayout from './Layout/Public/Layout';
import Home from './Home/Home';
import Conocenos from './Conocenos/Conocenos';
import Agenda from './Agenda/Agenda';
import Detalle from './Agenda/Detalle';
import Destinos from './Destinos/Destinos';
import AgendaDestino from './Destinos/Agenda';
import Blog from './Blog/Blog';
import BlogPost from './Blog/BlogPost';
import Contacto from './Contacto/Contacto';
import Acceso from './Acceso/Acceso';

// Private Components =>
import PrivateLayout from './Layout/Private/Layout';
import Dashboard from './Dashboard/Dashboard';
import Historial from './Historial/Historial';
import Mensajes from './Mensajes/Mensajes';
import Misviajes from './Misviajes/Misviajes';
import Perfil from './Perfil/Perfil';
import Abono from './Abonos/Abono';

// Utilities Components
import Out from './Utilities/Out';
import LoggedOut from './Utilities/LoggedOut';
import Snack from './Utilities/Snack';
import {API} from './Utilities/Helpers';

// Google Tag Manager
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-MG94NLD'
}
TagManager.initialize(tagManagerArgs);

// Redux Store is initialized here =>
const store = createStore(CombinedReducers, applyMiddleware(thunk));



// Get/Set Site Vars =>
// Validate Token on refresh =>
var state    = store.getState();
API(`${state.global.API}getVars`).then(res => res.json()).then(json => {
    store.dispatch({
        type: 'SET-SITE-VARS',
        payload	: json
    });
});



const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {

    // SEO:
    var section = document.URL.split(window.location.host+"/")[1].split("/")[0];

    // Almacena la sección actual en el state:
    store.dispatch({
        type: 'SET-CURRENT-SECTION',
        payload	: section
    });

    switch(section){
        case 'conocenos':
            document.title = 'Conócenos: Somos una empresa 100% Tapatía | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Queremos hacer de cada viaje una nueva experiencia, donde la diversión y convivencia vayan de la mano de actitudes responsables dirigidas a la sustentabilidad.";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba, sustentabilidad, experiencias , diversión, convivencia";
        break;

        case 'agenda':
            document.title = 'Próximos viajes: Siempre un nuevo destino | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Ve preparando tus maletas para salir de aventura con nosotros, atrévete a vivir la experiencia en estos próximos viajes que estamos preparando para ti. ";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba, sustentabilidad, experiencias , diversión, convivencia, próximas salidas, puentes, playas";
        break;

        case 'nuestros-destinos':
            document.title = 'Nuestros destinos: Vive el Ecoturismo | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Conoce los destinos donde podrás encontrarte con la armonía del medio ambiente fomentando la práctica del verdadero ecoturismo, accesible para toda persona.";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba, sustentabilidad, experiencias , diversión, convivencia, próximas salidas, puentes, playas ";
        break;

        case 'blog':
            document.title = 'Blog: Un vistazo a nuestras vivencias | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Dedicamos este espacio a compartir contigo nuestras reseñas, fotografías, vivencias y datos interesantes sobre los destinos que hemos visitado. ¡Bienvenido!";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba, sustentabilidad, experiencias , diversión, convivencia, próximas salidas, puentes, playas, reseñas ";
        break;

        case 'contacto':
            document.title = 'Contacto: Estamos a un clic de distancia | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Nos encontramos en Calle Garibaldi 753, Col. de Jesús, CP.44200 Guadalajara, Jal. Tels:+52 1 3319077565 / 01 33 2015 9141 Correo: info@mar-iguanatours.com.mx";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba, sustentabilidad, experiencias , diversión, convivencia, próximas salidas, puentes, playas, reseñas ";
        break;

        default:
            document.title = 'Inicia la aventura ¡Atrévete a vivirlo! | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Nos encargamos de realizar todo tipo de excursiones con un enfoque ecoturístico, queremos llevarte a conocer los lugares más bellos de nuestro querido México.";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba";
        break;
    }



    return <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
}



ReactDOM.render(
    <Provider store={store}>
        <Router history={createBrowserHistory}>
            <div>
                <Switch>
                    {/* Public Routes */}
                    <AppRoute layout={PublicLayout} path="/" exact component={Home} />
                    <AppRoute layout={PublicLayout} path="/conocenos" component={Conocenos} />
                    <AppRoute layout={PublicLayout} path="/agenda" exact component={Agenda} />
                    <AppRoute layout={PublicLayout} path="/agenda/:slug" component={Detalle} />
                    <AppRoute layout={PublicLayout} path="/nuestros-destinos" exact component={Destinos} />
                    <AppRoute layout={PublicLayout} path="/nuestros-destinos/agenda/:id" exact component={AgendaDestino} />
                    <AppRoute layout={PublicLayout} path="/blog" exact component={Blog} />
                    <AppRoute layout={PublicLayout} path="/blog/:slug" component={BlogPost} />
                    <AppRoute layout={PublicLayout} path="/contacto" component={Contacto} />
                    <AppRoute layout={PublicLayout} path="/acceso" component={Acceso} />
                    <AppRoute layout={PublicLayout} path="/confirmar/:crypt" component={Home} />

                    {/* Private Routes */}
                    <AppRoute exact path="/perfil" layout={PrivateLayout} component={Dashboard} />
                    <AppRoute exact path="/perfil/mis-viajes" layout={PrivateLayout} component={Misviajes} />
                    <AppRoute exact path="/perfil/historial" layout={PrivateLayout} component={Historial} />
                    <AppRoute exact path="/perfil/mensajes" layout={PrivateLayout} component={Mensajes} />
                    <AppRoute exact path="/perfil/mi-informacion" layout={PrivateLayout} component={Perfil} />
                    <AppRoute exact path="/perfil/reportar-abono/:slug" layout={PrivateLayout} component={Abono} />
                </Switch>

                {/* Modal when session is required */}
                <Out/>

                {/* Modal when user logout */}
                {state.global.loggedout ? <LoggedOut/> : null }

                {/* SnackBar */}
                <Snack/>
            </div>
        </Router>
    </Provider>,
    document.getElementById('root')
);

//registerServiceWorker();