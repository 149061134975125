export const home = (state = {
	banners: false,
}, action) => {
	switch(action.type)
	{
		case "SET-HOME-BANNERS":
			state = {
				...state,
				banners : action.payload
			};
        break;
        
		default:
			return state;
	}

	return state
};