export function resetVars() {
	return (dispatch) => {
		dispatch({
			type 	: "SET-PROFILE", 
			payload	: false
		});
	};
}



export function handleWelcome() {
	return (dispatch, getState) => {
		var state = getState();
		dispatch({
			type 	: "MODAL-WELCOME", 
			payload	: !state.acceso.welcome
		});
	};
}


// Obtiene la información del perfil del usuario
export function getProfile() {
    return (dispatch, getState) => {
    	const state = getState();
    	const request = fetch(`${state.global.API}getProfile/${localStorage.getItem("token")}`);
	    request.then(res => res.json()).then(profile => {
			dispatch({
				type 	: "SET-PROFILE", 
				payload	: profile
			});
	    })    	
    }
}



export function updateProfile(e) {
	return (dispatch, getState) => {
		var state    = getState();
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});
		const token    = state.global.fetchHeaders.get("x-mit-token");

		var form     = e.target;
		var formData = new FormData(form);
		var request  = new Request(`${state.global.API}updateProfile/${token}`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(json => {
			if(json.errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: json.errors[0]
				});				
				dispatch({
					type 	: "SET-SUBMITING", 
					payload	: false
				});
			}
			else
			{
				dispatch({
					type 	: "PROFILE-UPDATED", 
					payload	: true
				});

				dispatch({
					type 	: "SET-SUBMITING", 
					payload	: false
				});			
			}
		});
	};
}



export function closeProfileUpdated() {
	return (dispatch) => {
		dispatch({
			type 	: "PROFILE-UPDATED", 
			payload	: false
		});
	};
}