export function userMenu() {
	return (dispatch, getState) => {
		var state = getState();
		var status = state.global.usermenu
		dispatch({
			type 	: "SET-USER-MENU", 
			payload	: !status
		});
	};
}


export function mobileMenu() {
	return (dispatch, getState) => {
		var state = getState();
		var status = state.global.mobilemenu
		dispatch({
			type 	: "SET-MOBILE-MENU", 
			payload	: !status
		});
	};
}


export function updateWindowWidth() {
	return (dispatch) => {
		dispatch({
			type 	: "UPDATE-WINDOW-WIDTH", 
			payload	: window.innerWidth
		});
	};
}



