import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {closeProfileUpdated} from './Actions';

/****************************************
    Return Render =>
****************************************/
class Welcome extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
				<Dialog
					fullScreen={classes}
					open={this.props.profile.updated}
					onClose={() => this.props.closeProfileUpdated()}
					aria-labelledby="responsive-dialog-title"
					className="mit-dialog"
				>
					<DialogTitle>
						Datos actualizados
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i className="far fa-check-circle"></i>
						<DialogContentText>
							Tu información de perfil ha sido actualizada exitosamente.
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<button type="button" onClick={() => this.props.closeProfileUpdated() } className={"cta gray"}>cerrar</button>
					</DialogActions>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        closeProfileUpdated
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Welcome); 