export const profile = (state = {
	profile: false,
	updated: false,
}, action) => {
	switch(action.type)
	{
		case "SET-PROFILE":
			state = {
				...state,
				profile : action.payload
			};
		break;

		case "PROFILE-UPDATED":
			state = {
				...state,
				updated : action.payload
			};
		break;		

		default:
			return state;
	}

	return state
};