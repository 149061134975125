import React from 'react';
import {compose, withStateHandlers} from 'recompose';
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


// Actions =>
import {sendContact} from './Actions';


// Componetns =>
import Wrapper from '../Utilities/Wrapper';
import Thanks from './Thanks';


// Google Maps
import { GoogleMap,withGoogleMap,withScriptjs,Marker,InfoWindow } from 'react-google-maps';





/****************************************
    Return Render =>
****************************************/
class Contacto extends React.Component {

    render() {
        const vars = this.props.global.variables;

        const Gmap = compose(
            withStateHandlers(() => ({
                marker: true,
            }), {
                toggleMarker: ({marker}) => () => ({ marker: !marker }),
            }),
            withScriptjs,
            withGoogleMap
        )(props =>
            <GoogleMap defaultZoom={15} defaultCenter={{ lat: 20.6810097, lng: -103.3553837 }}>
                {/* MIT */}
                <Marker position={{ lat: 20.6810097, lng: -103.3553837 }} onClick={props.toggleMarker}>
                    {props.marker && <InfoWindow onCloseClick={props.toggleMarker}>
                        <div style={{paddingTop:"20px", "width" : "280px", "overflow":"hidden"}}>
                            <b style={{ display:"block", marginBottom:"12px"}}>Oficinas Mar-Iguana Tours</b>
                            <p><br/>{vars.direccion}</p>
                            <a style={{ fontSize:"12px", display:"block","marginTop":"-25px"}} target="_blank" rel="noopener noreferrer" href="https://www.google.com.mx/maps/dir/20.5382019,-103.460966/Mar+Iguana+Tours,+Calle+Garibaldi+753,+Centro,+Zona+Centro,+44100+Guadalajara,+Jal./@20.6097823,-103.4788648,12z/data=!3m1!4b1!4m16!1m6!3m5!1s0x0:0xef6ce0d411184cce!2sMar+Iguana+Tours!8m2!3d20.6810097!4d-103.3553837!4m8!1m1!4e1!1m5!1m1!1s0x8428ae02d037a357:0xef6ce0d411184cce!2m2!1d-103.3553837!2d20.6810097">
                                <i style={{ marginRight:"5px"}} className="fas fa-location-arrow"></i>
                                Cómo llegar
                            </a>
                        </div>
                    </InfoWindow>}
                </Marker>                                                       
            </GoogleMap>
        );




        return (
            <div id="contacto">
                <div className="breakpoint">
                    <h1>Contacto</h1>
                </div>
                <Gmap
                    className="map"
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD87K_FVReSbtwrx86NMRo0Zo5234St1hQ&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />

                <div className={"breakpoint  mit-forms"}>
                    <Grid container spacing={40}>
                        <Grid item md={7} sm={12} xs={12}>
                            <h2>Solicitar más información</h2>
                             <form onSubmit={(e) => {this.props.sendContact(e); e.preventDefault();}}>
                                <Grid container spacing={24}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <label>Nombre:</label>
                                        <input name="name" type="text" placeholder="Nombre:" autoComplete="off"/>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <label>E-mail:</label>
                                        <input name="email" type="text" placeholder="E-mail:" autoComplete="off"/>
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <label>Teléfono:</label>
                                        <input name="phone" type="text" placeholder="Teléfono:" autoComplete="off"/>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <label>Asunto:</label>
                                        <input name="subject" type="text" placeholder="Asunto:" autoComplete="off"/>
                                    </Grid>   

                                    <Grid item md={12} sm={12} xs={12}>
                                        <label>Comentarios:</label>
                                        <textarea name="comments" placeholder="Comentarios:"></textarea>
                                    </Grid>  

                                    <Grid item md={12} sm={12} xs={12}>
                                        <Wrapper issubmiting={this.props.global.issubmiting}>
                                            <button className="cta green" type="submit"><i className="far fa-comment-alt"></i> Enviar mis comentarios</button>
                                        </Wrapper>
                                    </Grid>                                                                      
                                </Grid>                                
                            </form>
                        </Grid>
                        <Grid item md={5} sm={12} xs={12} className="contact-info">
                            <h2>Dirección</h2>
                            <p>{vars.direccion}</p>
                            
                            <a href={"tel:"+vars.telefono}>{vars.telefono}</a>
                            <a href={"mailto:"+vars.correo}>{vars.correo}</a>
                        </Grid>
                    </Grid>
                </div>

                {/* Agradecimiento de la forma de contacto */}
                <Thanks/>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        sendContact
    }, dispatch);
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps)
)(Contacto);