import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Routing =>
import { Link } from 'react-router-dom';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {getDestinations} from './Actions';

// With Styles =>
const styles = theme => {
    return {
        progress : {
            color: '#00a550',
            marginTop: '80px'
        }
    };
};


/****************************************
    Return Render =>
****************************************/
class Destinos extends React.Component {

    componentWillMount(){
        this.props.getDestinations(this.props);
    }

    render() {
        const { classes } = this.props;
        return (
            <div id="nuestros-destinos">
                <div className="breakpoint">
                    <h1>Nuestros Destinos</h1>
                    <Grid container spacing={24}>
                        {this.props.destinos.destinos ? (
                            this.props.destinos.destinos.map(item => {
                                return (                                          
                                    <Grid item key={item.id} md={3} sm={6} xs={12} className={"blog-item"}>
                                        <div className={"img-holder"}>
                                            <img src={ item.img } alt={item.name} />
                                        </div>
                                        <h2>{ item.name }</h2>
                                        <Link to={"/nuestros-destinos/agenda/"+item.id} className="cta green">Ver Fechas</Link>
                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid container justify="center">
                                <Grid item md={2}>
                                    <CircularProgress className={classes.progress}/>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>           
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getDestinations
    }, dispatch);
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, matchDispatchToProps)
)(Destinos);