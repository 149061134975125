import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Link } from 'react-router-dom'

// Actions =>
import {getHistory, resetVars} from './Actions';


// Componetns =>
import Item from './Item';
import Wrapper from './Wrapper';


/****************************************
    Return Render =>
****************************************/
class Historial extends React.Component {

    componentWillMount(){
        this.props.resetVars();
        this.props.getHistory();
    }


    render() {
        const history = this.props.historial.history;
        if(history === false)
        {
            return(<Wrapper>
                <Grid container justify="center">
                    <Grid item xs={12} align="center">
                        <CircularProgress style={{ color: "green" }} thickness={1.5}/>
                    </Grid>
                </Grid>
            </Wrapper>)
        }
        else
        {
            if(history.length > 0)
            {
                return(<Wrapper>
                    {history.map(item => {
                        return (<Item key={item.uuid} info={item} />);
                    })}
                </Wrapper>)
            }
            else
            {   
                return(<Wrapper>
                    <div id="empty-message">
                        <p>Aún no cuentas con un historial de viajes. Te recomendamos visitar nuestra agenda de eventos, seguro encontrarás uno para tí</p>
                        <Link to={"/agenda"} className="cta green"><i className="far fa-calendar-check"></i> agenda de eventos</Link>
                    </div>
                </Wrapper>)
            }
        }
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getHistory,
        resetVars
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Historial); 