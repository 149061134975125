import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {eventDepositsInfo, initDepositVars} from './Actions';


// Componetns =>
import First from './First';
import Next from './Next';
import Thankyou from './Thankyou';


/****************************************
    Return Render =>
****************************************/
class Abono extends React.Component {

    // Will Mount =>
    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.initDepositVars();
        var spl = document.URL.split('/');
        var slug = spl[spl.length-1];
        this.props.eventDepositsInfo(slug);
    }

    render() {
        const info = this.props.abono.info;
        if(info === false)
        {
            return(
                <Grid container justify="center">
                    <Grid item xs={12} align="center">
                        <CircularProgress style={{ color: "green" }} thickness={1.5}/>
                    </Grid>
                </Grid>)
        }
        else
        {
            if(this.props.abono.thankyou)
            {
                return(<Thankyou/>);
            }
            else
            {
                if(info.isnew)
                return(<First/>); // <= Es primer deposito
                else
                return(<Next/>);  // <= Ya existe otro deposito       
            }
        }
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        eventDepositsInfo,
        initDepositVars
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Abono); 