export function getDestinations(props) {
	const request = fetch(`${props.global.API}getDestinations`);
    return (dispatch) => {
	    request.then(res => res.json()).then(items => {
			dispatch({
				type 	: "SET-DESTINATIONS", 
				payload	: items
			});
	    })    	
    }
}



export function getAgenda(id) {
    return (dispatch, getState) => {
		var state = getState();
		const request = fetch(`${state.global.API}getAgenda/${id}`);
	    request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "SET-DESTINATION-AGENDA", 
				payload	: json
			});	
	    })    	
    }
}