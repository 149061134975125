import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {formRegister, modalForgot} from './Actions';

// Componetns =>
import Wrapper from '../Utilities/Wrapper';


/****************************************
    Return Render =>
****************************************/
class Register extends React.Component {
    render() {
        //const { classes } = this.props;
        return (
            <div>
        		<h2><i className="far fa-user-circle"></i> Registrarme</h2>

        		<p>Favor de completar los siguientes datos para crear tu cuenta de mar-iguanatours. <strong>Te llegará un mail de confirmación cuando tu cuenta se ha creado satisfactoriamente.</strong></p>
				<p>Una vez creada y verificada podrás apartar tus viajes y recibir información exclusiva de nuestros tours.</p>
				<b>Es necesario que incluyas tu nombre completo real.</b>
                <form onSubmit={(e) => {this.props.formRegister(e); e.preventDefault();}}>
                    <Grid container spacing={16}>
                        <Grid item md={6} sm={12} xs={12}>
                            <label>Nombre(s):</label>
                            <input type="text" name="firstname" placeholder="Nombre:"/>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <label>Apellidos:</label>
                            <input type="text" name="lastname" placeholder="Apellidos:"/>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <label>E-mail:</label>
                            <input type="text" name="email" placeholder="E-mail:"/>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <label>Celular:</label>
                            <input type="text" name="phone" placeholder="Celular:"/>
                        </Grid>                                                                        

                        <Grid item md={6} sm={12} xs={12}>
                            <label>Talla de Camisa:</label>
							<select name="tshirt">
				                <option value="">&mdash; Selecciona &mdash;</option>
				                <optgroup label="Adulto">
				                    <option value="Chica">Chica</option>
				                    <option value="Mediana">Mediana</option>
				                    <option value="Grande">Grande</option>
				                    <option value="Extra Grande">Extra Grande</option>
				                </optgroup>
				                <optgroup label="Infantil">
				                    <option value="Inf. Chica">Chica</option>
				                    <option value="Inf. Mediana">Mediana</option>
				                    <option value="Inf. Grande">Grande</option>
				                </optgroup>                         
				            </select>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <label>Género:</label>
							<select name="gender">
				                <option value="">&mdash; Selecciona &mdash;</option>
				                <option value="Femenino">Femenino</option>
				                <option value="Masculino">Masculino</option>
				            </select>
                        </Grid>      

                        {/* Submit */}                                                                  
                        <Grid item md={6} sm={12} xs={6}>
                            <Wrapper issubmiting={this.props.global.issubmiting}>
                                <button className="cta green" type="submit"><i className="far fa-user-circle"></i> Crear mi cuenta</button>
                            </Wrapper>
                        </Grid>
                        <Grid item md={6} sm={12} xs={6} align="right">
                            <button type="button" style={{ marginTop: '10px'}} className="link-more" onClick={() => this.props.modalForgot(true) }>Olvidé mi contraseña.</button>
                        </Grid>                        
                    </Grid>                                
                </form>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        formRegister,
        modalForgot
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Register); 