import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';

// Router =>
import { Link, NavLink} from 'react-router-dom';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {logOut} from '../../Acceso/Actions';
import {userMenu, mobileMenu} from './Actions';
import {dialogPassword} from '../../Utilities/Actions';
import {getCounters} from "../Private/Actions";

/****************************************
    Return Render =>
****************************************/
class HeaderMobile extends React.Component {

    componentWillMount(){
        this.props.getCounters();
    }

    switchMenu(){
		const vars = this.props.global.variables;
		return(
		 	(this.props.global.mobilemenu) ?
            (
            	<div className="mobile-menu" onClick={() => this.props.mobileMenu() }>
		            <nav>
		                <ul>
		                    <li><NavLink exact to={"/"}>INICIO</NavLink></li>
		                    <li><NavLink exact to={"/conocenos"}>CONÓCENOS</NavLink></li>
		                    <li><NavLink exact to={"/agenda"}>PRÓXIMOS VIAJES</NavLink></li>
		                    <li><NavLink exact to={"/nuestros-destinos"}>NUESTROS DESTINOS</NavLink></li>
		                    <li><NavLink exact to={"/blog"}>BLOG</NavLink></li>
		                    <li><NavLink exact to={"/contacto"}>CONTACTO</NavLink></li>
		                </ul>
		            </nav>

	        		{/* Acceso a Usuario */}
	        		<Link to={"/acceso"} className="cta">ACCESO A USUARIOS</Link>

	        		{/* Contacto */}
					<a className="mail" href={"mailto:"+vars.correo}>{vars.correo}</a>
					<a className="tel" style={{ marginBottom: "0px"}} href={"tel:"+vars.telefono1}>TEL 1): <b>{vars.telefono1}</b></a>
					<a className="tel" href={"tel:"+vars.telefono2}>TEL 2): <b>{vars.telefono2}</b></a>


					{/* Follow Us */}
                    <div className={"follow-us"}>
                        <span>Síguenos en:</span>
                        <ul>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href={vars.facebook}><i className="fab fa-facebook-f"></i></a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href={vars.twitter}><i className="fab fa-twitter"></i></a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href={vars.instagram}><i className="fab fa-instagram"></i></a>
                            </li>
                            <li className="last">
							<a href={"https://wa.me/"+vars.whatsapp}><i className="fab fa-whatsapp"></i></a>
                            </li>
                        </ul>
                    </div>
		        </div>
            )
	 		: false
	 	)
    }


    switchUserMenu(){
		const counters = this.props.global.counters;
    	if(this.props.global.usermenu)
    	{
    		return(
        		<nav>
        			<NavLink className="mitem" exact to={"/perfil"}>Inicio <b>({ counters.notifications || "0" })</b></NavLink>
        			<NavLink className="mitem" exact to={"/perfil/mis-viajes"}>PRÓXIMOS VIAJES <b>({counters.upcoming || "0"})</b></NavLink>
        			<NavLink className="mitem" exact to={"/perfil/historial"}>HISTORIAL DE VIAJES <b>({counters.historic || "0"})</b></NavLink>
        			<NavLink className="mitem" exact to={"/perfil/mi-informacion"}>MI PERFIL</NavLink>
					<NavLink className="mitem" exact to={"/perfil/mensajes"}>MENSAJES <b>({ counters.messages || "0" })</b></NavLink>
					<button className="mitem" type="button" to={"/perfil/mi-informacion"} onClick={() => this.props.dialogPassword(true) }>CAMBIAR CONTRASEÑA</button>
                    <button className="mitem log-out" type="button" onClick={() => this.props.logOut() }><i className="fas fa-power-off"></i> cerrar sesión</button>
        		</nav>
    		)
    	}
    	else
    	{
    		return false;
    	}
    }


    welcomeUser(){
    	let user = this.props.global.user;
    	if(user.gender === 'Masculino')
    	{
    		return(<div><small>Bienvenido</small><big>{ user.displayname }</big></div>);
    	}
    	else
    	{
			return(<div><small>Bienvenida</small><big>{ user.displayname }</big></div>);
    	}
    }


    render() {
        return (
	        <header className="header-mobile">
	            <div className={"breakpoint"}>
	                <Grid container spacing={24}>
	                    <Grid item sm={4} xs={8}>
	                        <Link to={"/"} className="logo">
	                            <img src={"/img/general/logo-mobile-aniversario-mit.png"} alt={"MIT Logo"}/>
	                        </Link>
	                    </Grid>
	                    <Grid item sm={8} xs={4} align="right">
		                     <button type="button" onClick={() => this.props.mobileMenu() } className="menu-trigger"><i className="fas fa-bars"></i></button>
	                    </Grid>
	                	{/* Menú */}
	                    { this.switchMenu() }
	                </Grid>

                    {/* Navegación de Usuarios con sesión */}
                    {this.props.global.authorized ? (
    	                <Grid container>
    	                	<Grid item xs={12}>
    	                		<div className="user-options" onClick={() => this.props.userMenu() }>
    	                			<Grid container>
    	                				<Grid item xs={7}>
    			                			{this.welcomeUser()}
    			                		</Grid>
    			                		<Grid item xs={5}>
    			                			<span>
    			                				Mi perfil
    			                				<i className="fas fa-sort-down"></i>
    			                			</span>
    			                		</Grid>
    			                	</Grid>
    			                	{/* Menú de Usuario */}
    			                	{ this.switchUserMenu() }
    	                		</div>
    	                	</Grid>
    	                </Grid>
                    ) : (false) }

	            </div>
	        </header>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        logOut,
        userMenu,
		mobileMenu,
		dialogPassword,
		getCounters
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(HeaderMobile);