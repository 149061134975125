import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


import { Link } from 'react-router-dom'

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


// Actions =>
import {getPost, initVars} from './Actions';

// Componetns =>
import Blog from './Blog';


// With Styles =>
const styles = theme => {
    return {
        progress : {
        	margin: '0 auto',
            color: '#00a550',
            marginTop: '40px',
            marginBottom: '40px',
        }
    };
};


/****************************************
    Return Render =>
****************************************/
class BlogPost extends React.Component {


    constructor(props){
        super(props);
        var url  = document.URL.split('/');
        var slug = url[(url.length - 1)];
        this.state = {
            slug: slug
        }
    }



    componentWillMount(){
        this.props.getPost();
    }

    render() {
    	const { classes } = this.props;
    	const post = this.props.blogs.blogpost;


        return (
            <div id="blog-post">
                <div className={"breakpoint"}>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                           <h1>Blog</h1>
                        </Grid>
						<Grid item md={6} sm={12} xs={12} align="right">
							<Link to={"/blog"} className={"link-more"} style={{ paddingTop:'38px'}}>&laquo;Ver todos los posts</Link>
						</Grid>						
                    </Grid>
                </div>

	            <div className="gray-container">
	            	<div className={"breakpoint"}>				
		                {this.props.blogs.blogpost ? (
		                	<div>
		                		<Grid container>
		                			<Grid item xs={12}>
		                				<div className="img-holder">
		                					<img src={post.imagen} alt={post.title }/>
		                				</div>
		                			</Grid>
		                		</Grid>

		                		<Grid container justify={"center"}>
		                			<Grid item xs={10}>
				                		<Grid container>
				                			<Grid item xs={12}>
				                				<h2>{post.title}</h2>
				                			</Grid>
				                		</Grid>

				                		<Grid container>
				                			<Grid item md={2} sm={6} xs={12}>
				                				<span>{post.publish_at}</span>
				                			</Grid>
				                			<Grid item md={10} sm={6} xs={12}>
				                				<span>Escrito por: {post.author}</span>
				                			</Grid>		                			
				                		</Grid>

				                		<Grid container>
				                			<Grid item xs={12}>
				                				<div className={"post-text"} dangerouslySetInnerHTML={{__html: post.content}} />
				                			</Grid>
				                		</Grid>
				                	</Grid>
				                </Grid>
	                		</div>         		
		                ) : (
		                    <Grid container justify="center">
		                        <Grid item xs={12} align={"center"}>
		                            <CircularProgress className={classes.progress}/>
		                        </Grid>
		                    </Grid>
		                )}
		            </div>
	            </div>

	        	{/* Other Blogs */}
	        	<Blog/>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getPost,
        initVars
    }, dispatch);
}

export default compose(
	withStyles(styles),
    connect(mapStateToProps, matchDispatchToProps)
)(BlogPost); 