import React from 'react';
import compose from 'recompose/compose';
// Redux =>
import {connect} from 'react-redux';

// Material UI =>
import CircularProgress from '@material-ui/core/CircularProgress';


/****************************************
    Return Render =>
****************************************/
class Wrapper extends React.Component {
    render() {
        const color = (this.props.color) ? this.props.color : 'green';
        const issubmiting = (this.props.global.issubmiting || this.props.issubmiting) ? true : false;
        return (
            <div id="wrapper">
            	{issubmiting ? (
            		<CircularProgress style={{ color: color }} thickness={1.5}/>
            	) : (
            		<div>{this.props.children}</div>
            	)}
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


export default compose(
    connect(mapStateToProps, null)
)(Wrapper); 