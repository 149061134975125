export const destinos = (state = {
	destinos: false,
	agenda: false
}, action) => {
	switch(action.type)
	{
		case "SET-DESTINATIONS":
			state = {
				...state,
				destinos : action.payload
			};
		break;	


		case "SET-DESTINATION-AGENDA":
			state = {
				...state,
				agenda : action.payload
			};
		break;			

		default:
			return state;
	}

	return state
};