import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
//import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


/****************************************
    Return Render =>
****************************************/
class Item extends React.Component {
    
    render() {
        const item = this.props.info;
        return (
            <Grid container spacing={24} key={"event-item-"+item.event.id}>
                <Grid item md={12} sm={12} xs={12}>
                   <div className="event-holder">
                        {/* Preview */}
                        <section className="preview" style={{ marginBottom: '0px' }}>
                        <Grid container spacing={24}>
                                <Grid item md={3} sm={3} xs={12}>
                                    <div className={"img-holder"}>
                                        <img src={item.event.banners[0].path} alt={item.event.name}/>
                                    </div>
                                </Grid>
                                <Grid item md={9} sm={9} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3>{item.event.name}</h3>
                                        </Grid>
                                        <Grid item xs={12} className="period">
                                            <span>{item.event.period}</span>
                                        </Grid>
                                        <Grid item xs={12} className="code">
                                            <label>Código de reservación</label>
                                            <span>{item.code}</span>
                                        </Grid>
                                        {/* Paquete + Asiento + Adicionales */}
                                        <Grid item xs={12} className="more">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-bus"></i>
                                                    <span>Camión {item.seat.busord}, Asiento {item.seat.seat}</span>
                                                </li>
                                                <li>
                                                    <i className="fas fa-dollar-sign"></i>
                                                    <span>{ item.package.name } <b>{ item.package.price }</b></span>
                                                </li>
                                                {/* Adicionales */}
                                                {item.additionals.map(add => {
                                                    return(
                                                        <li key={"add-"+add.name}>
                                                            <i className="fas fa-plus-circle"></i>
                                                            <span>{ add.name }</span>
                                                        </li>
                                                    )
                                                })}     
                                                { item.travellers.length > 0 ? (
                                                    <li>
                                                        <i className="fas fa-users"></i>
                                                        <span>Viajaste con: 
                                                            {item.travellers.map(traveller => {
                                                                return(<b style={{ marginLeft: "10px" }}>{ traveller.firstname } { traveller.lastname }</b>)
                                                            })}  
                                                        </span>
                                                    </li>
                                                ) : (null)}                                                                                                                                
                                            </ul>
                                        </Grid>         
                                    </Grid>
                                </Grid>
                                {/*<Grid item md={3} sm={3} xs={12} className="chart"></Grid>*/}
                            </Grid>
                        </section>
                   </div> 
                </Grid>
            </Grid>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch);
}
********************************************/


export default compose(
    connect(mapStateToProps, null)
)(Item); 