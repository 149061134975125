import React from 'react';
import compose from 'recompose/compose';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

// Actions
import {dialogTerms, getTerms} from '../../Utilities/Actions';

class Terms extends React.Component {


    componentWillMount(){
        this.props.getTerms();
    }
    createMarkup(html) {
        return {__html: html};
    }
      


    render() {
        return (
            <Dialog
                open={this.props.global.dterms}
                onClose={() => this.props.dialogTerms(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Términos y Condiciones"}</DialogTitle>
                <DialogContent style={{ fontSize:'12px'}}>
                    {this.props.global.terms ? (
                        <div dangerouslySetInnerHTML={this.createMarkup(this.props.global.terms)}></div>
                    ) : (
                        <div style={{ width: "300px", textAlign:"center", marginTop:"30px"}}>
                            <CircularProgress style={{ color: 'green'}} thickness={2} />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.dialogTerms(false)} className="cta black">Cerrar</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
function mapStateToProps(state){
    return state;
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        dialogTerms,
        getTerms
    }, dispatch);
}
export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Terms); 