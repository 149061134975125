// Helpers
import {API} from '../Utilities/Helpers';

import {getCounters} from '../Layout/Private/Actions';

// Reset Vars
export function resetVars() {
    return (dispatch, getState) => {
        dispatch({
            type 	: "SET-THREADS", 
            payload	: false
        }); 	

        dispatch({
            type 	: "SET-THREAD-MESSAGES", 
            payload	: false
        });
    }
}

// Obtiene los hilos de conversación del usuario:
export function getThreads() {
    return (dispatch, getState) => {
		const state = getState(); 	
		var request = API(`${state.global.API}getThreads`, 'GET');
	    request.then(res => res.json()).then(items => {
			dispatch({
				type 	: "SET-THREADS", 
				payload	: items
			});
	    })    	
    }
}


// Obtiene las notificaciones del perfil del usuario:
export function getMessages(id = false ) {
    var pid = (id) ? '/'+id : '';
    return (dispatch, getState) => {
        dispatch({
            type 	: "SET-ACTIVE-THREAD", 
            payload	: (id === '') ? false : id
        });
        dispatch({
            type 	: "SET-THREAD-MESSAGES", 
            payload	: false
        });
		const state = getState(); 	
		var request = API(`${state.global.API}getMessages${pid}`, 'GET');
	    request.then(res => res.json()).then(items => {
			dispatch({
				type 	: "SET-THREAD-MESSAGES", 
				payload	: items
			});
			// Re-calculate Counters:
			dispatch(getCounters());
			// Re-calculate threads counter:
			dispatch(getThreads());
	    })    	
    }
}

export function sendMessage(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
        });

		var state    = getState();
		var form     = e.target;
		var formData = new FormData(e.target);
		var request  = new Request(`${state.global.API}sendMessage/${localStorage.getItem("token")}`, {
			method: 'POST',
			body: formData
		});

		fetch(request).then(res => res.json()).then(errors => {
			if(errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: errors[0] // Mostramos el primero error en el nackbar
				});				
			}
			else
			{
				form.reset();
				dispatch(getMessages());
			}

			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
        });
	};
}
