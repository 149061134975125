import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';


// Redux =>
//import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';



// Componetns =>
import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import PasswordSent from './PasswordSent';
import Registered from './Registered';

/****************************************
    Return Render =>
****************************************/
class Acceso extends React.Component {

    componentDidMount(){
        window.scrollTo(0, 240);
    }


    render() {
        //const { classes } = this.props;
        return (
            <div id="acceso">
                <div className="breakpoint">
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <h1>Acceso a Usuarios</h1>
                        </Grid>
                    </Grid>

                    <Grid container className={"mit-forms"} spacing={40}>
                        <Grid item md={5} sm={5} xs={12}>
                            {/* Login */}
                            <Login/>
                        </Grid>   

                        <Grid item md={1} sm={1} xs={12}>
                            <div className="divider"></div>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            {/* Register */} 
                            <Register/>                         
                        </Grid>                                             
                    </Grid>
                </div>

                {/* Modal Forgot */}
                <Forgot/>

                {/* Modal Password Sent */}
                <PasswordSent/>

                {/* Confirmar el registro */}
                <Registered/>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // ???
    }, dispatch);
}
********************************************/

export default compose(
    connect(mapStateToProps, null)
)(Acceso); 