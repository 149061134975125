import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {getNotifications, getImg, discardNotification} from './Actions';

// Componetns =>
import Wrapper from './Wrapper';
import Img from './Img';



// Styles =>
const styles = {
  progress: {
        color: '#18a951',
        marginTop: '30px',
        marginLeft:'49%'
  },
};

/****************************************
    Return Render =>
****************************************/
class Notificaciones extends React.Component {

    componentDidMount(){
        this.props.getNotifications();
    }


    render() {
        const notifications = this.props.notifications.items;
        if(notifications === false)
        {
            return(<Wrapper>
                <Grid container justify="center">
                    <Grid item md={12} sm={12} xs={12} align="center">
                        <CircularProgress style={{ color: "green" }} thickness={1.5}/>
                    </Grid>
                </Grid>
            </Wrapper>)
        }
        else
        {
            if(notifications.length > 0)
            {
                return(<Wrapper>
                    <Grid container spacing={24}>
                        {notifications.map(item => {
                            return (
                                <Grid key={"notification"+item.id} item md={12} sm={12} xs={12}>
                                    <Card className={item.color}>
                                        <CardContent>
                                            <Typography gutterBottom variant="headline" component="h2">{ item.status }</Typography>
                                            <label dangerouslySetInnerHTML={{__html: item.notification }}/>
                                            { item.log ? <Typography component="p"><small>Comentario:</small><br/>{ item.log }</Typography> :  null }
                                        </CardContent>
                                        <CardActions>
                                            {item.deposit ? 
                                             <Tooltip title="Ver imagen de depósito">
                                                <IconButton onClick={() => this.props.getImg(item.deposit) }>
                                                    <i className="far fa-image" style={{fontSize:'18px'}}></i>
                                                </IconButton>
                                              </Tooltip>
                                              : null }
                                              {/*
                                             <Tooltip title="Descartar esta notificación (el depósito no se borrará)">
                                                <IconButton onClick={() => this.props.discardNotification(item.id) }>
                                                    <i className="far fa-trash-alt" style={{fontSize:'18px', color: 'red'}}></i>
                                                </IconButton>
                                              </Tooltip>
                                              */}
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {/* Modal de la Imagen */}
                    <Img/>
                </Wrapper>)
            }
            else
            {
                return(<Wrapper>
                    <div id="empty-message">
                        <p><b>Excelente</b>, no tienes notificaciones por revisar.</p>
                    </div>
                </Wrapper>)
            }
        }
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getImg,
        getNotifications,
        discardNotification
    }, dispatch);
}

export default compose(
	withStyles(styles),
    connect(mapStateToProps, matchDispatchToProps)
)(Notificaciones); 