import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {getConocenos} from './Actions';



/****************************************
    Return Render =>
****************************************/
class Conocenos extends React.Component {

    componentDidMount(){
        this.props.getConocenos();
    }
    createMarkup(html) {
        return {__html: html};
    }
      


    render() {

        if(this.props.conocenos.info)
        {
            return (
                <div id="conocenos">
                    <div className="breakpoint">
                        <h1>Conócenos</h1>

                        <Grid container spacing={40}>
                            <Grid item md={5} sm={12} xs={12}>
                                <div className={"img-holder"}>
                                    <img src={this.props.conocenos.info.izquierda} alt={"Quiénes Somos"}/>
                                </div>
                            </Grid>
                            <Grid item md={7} sm={12} xs={12}>
                                <h2>Quiénes Somos</h2>
                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.conocenos.info.quienes_somos)}></div>

                                <h2>Misión</h2>
                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.conocenos.info.mision)}></div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={40}>
                            <Grid item md={7} sm={12} xs={12}>
                                <h2>Visión</h2>
                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.conocenos.info.vision)}></div>
                            </Grid>
                            <Grid item md={5} sm={12} xs={12}>
                                <div className={"img-holder"}>
                                    <img src={this.props.conocenos.info.derecha} alt={"Quiénes Somos"}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }
        else
        {
            return (
                <div id="conocenos">
                    <div className="breakpoint">
                        <h1>Conócenos</h1>
                        <Grid container justify="center">
                            <Grid item xs={12} align={"center"}>
                                <CircularProgress style={{ color: 'green', display: "inline-block"}} thickness={2} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getConocenos
    }, dispatch);
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps)
)(Conocenos);