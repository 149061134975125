export function closeSnack(props) {
	return (dispatch) => {
		dispatch({
			type 	: "SNACK-BAR",
			payload	: false
		})
	};
}


export function isOut(res) {
	return (dispatch) => {
		dispatch({
			type 	: "IS-OUT",
			payload	: res
		})
	};
}

export function loggedOut(res) {
	return (dispatch) => {
		dispatch({
			type 	: "IS-LOGGED-OUT",
			payload	: res
		})
	};
}


export function dialogTerms(status) {
	return (dispatch) => {
		dispatch({
			type 	: "SET-DIALOG-TERMS",
			payload	: status
		});
	};
}



// Obtiene los términos y condiciones desde la API
export function getTerms() {
	return (dispatch, getState) => {
		var state    = getState();
		var request  = new Request(`${state.global.API}getTerms`);
		fetch(request).then(res => res.json()).then(terms => {
			dispatch({
				type 	: "SET-TERMS",
				payload	: terms.terminos_y_condiciones
			});

		});
	};
}

export function dialogPassword(status) {
	return (dispatch) => {

		// Default disallow password change
		dispatch({
			type 	: "SET-PASSWORD-CHANGE-ALLOWED",
			payload	: false
		});

		dispatch({
			type 	: "SET-DIALOG-PASSWORD",
			payload	: status
		});
	};
}





export function openModal(atype) {
	return (dispatch) => {
		dispatch({
			type 	: atype,
			payload	: true
		})
	};
}

export function closeModal(atype) {
	return (dispatch) => {
		dispatch({
			type 	: atype,
			payload	: false
		})
	};
}
