import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Link } from 'react-router-dom';


import {getEvents} from '../../Agenda/Actions';

// With Styles =>
const styles = theme => {
    return {
        progress: {
            color: '#fff',
            marginLeft: '45px'
        },
    };
};


// Componetns =>

/****************************************
    Return Render =>
****************************************/
class Footer extends React.Component {

    componentWillMount(){
        this.props.getEvents();
    }


    render() {
        const { classes } = this.props;
        const vars = this.props.global.variables;
        return (
            <footer>
                <div className="breakpoint">
                    <Grid container spacing={24}>
                        <Grid item md={3} sm={6} xs={12}>
                            <h2>Datos de contacto</h2>
                            <p>{vars.direccion}</p>
                            <a href={"mailto:"+vars.correo}>{vars.correo}</a>
                            <a href={"tel:"+vars.telefono1}>{vars.telefono1}</a>
                            <a href={"tel:"+vars.telefono2}>{vars.telefono2}</a>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <h2>Síguenos en</h2>
                            <ul>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href={"https://wa.me/"+vars.whatsapp}>
                                        <span><i className="fab fa-whatsapp"></i></span>
                                        WhatsApp
                                    </a>
                                </li>
                                <li>
                                    <a href={vars.facebook} target="_blank" rel="noopener noreferrer">
                                        <span><i className="fab fa-facebook-f"></i></span>
                                        Facebook
                                    </a>
                                </li>
                                <li>
                                    <a href={vars.twitter} target="_blank" rel="noopener noreferrer">
                                        <span><i className="fab fa-twitter"></i> </span>
                                        Twitter
                                    </a>
                                </li>
                                <li>
                                    <a href={vars.instagram} target="_blank" rel="noopener noreferrer">
                                        <span><i className="fab fa-instagram"></i></span>
                                        Instagram
                                    </a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <h2>Sitemap</h2>
                            <ul>
                                <li><Link to={"/"}>Inicio</Link></li>
                                <li><Link to={"/conocenos"}>Conócenos</Link></li>
                                <li><Link to={"/agenda"}>Agenda</Link></li>
                                <li><Link to={"/nuestros-destinos"}>Nuestros Destinos</Link></li>
                                <li><Link to={"/blog"}>Blog</Link></li>
                                <li><Link to={"/contacto"}>Contacto</Link></li>
                            </ul>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <h2>Próximos Eventos</h2>
                            <ul>
                                {this.props.agenda.events ? (
                                    this.props.agenda.events.map((item, idx) => {
                                        return (idx <= 4) ? <li key={"li-"+item.id} ><Link to={"/agenda/"+item.slug}>{item.name}</Link></li> : null
                                    })
                                ) : (
                                    <li><CircularProgress className={classes.progress}/></li>
                                )}
                            </ul>
                        </Grid>
                    </Grid>
                </div>
                <div className="bottom">
                    <img src={"/img/general/logo-aniversario-mit.png"} alt="MIT Logo" />
                </div>
            </footer>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getEvents
    }, dispatch);
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, matchDispatchToProps)
)(Footer);