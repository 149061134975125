import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Link } from 'react-router-dom'


// Buses CSS =>
import '../buses.css';

// Actions =>
import {setToggle} from './Actions';
import {getImg} from '../Notificaciones/Actions';


// Componetns =>
import Img from '../Notificaciones/Img';
import { UUID } from '../Utilities/Helpers';


/****************************************
    Return Render =>
****************************************/
class Item extends React.Component {
    
    render() {
        const item       = this.props.info;
        const index      = this.props.index;
        const visibility = (item.event.toggle) ? 'hidden' : '';
        const symbol     = (item.event.toggle) ? <button type="button" onClick={() => this.props.setToggle(index) } className={"exp-col"}><i className="fas fa-plus"></i></button> : <button type="button" onClick={() => this.props.setToggle(index) } className={"exp-col"}><i className="fas fa-minus"></i></button>;
        const align      = (this.props.global.windowwidth >= 600) ? "right" : "center";
        

        console.log(item);

        return (
            <Grid container spacing={24} key={"event-item-"+item.event.id}>
                <Grid  item xs={12}>
                   <div className="event-holder">
                        {/* Expand & Collapse */}
                        { symbol }
                        
                        {/* Preview */}
                        <section className="preview">
                            <Grid container spacing={24}>
                                <Grid item md={3} sm={3} xs={12}>
                                    <div className={"img-holder"}>
                                        <img src={item.event.banners[0].path} alt={item.event.name}/>
                                    </div>
                                </Grid>
                                <Grid item md={9} sm={9} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3>{item.event.name}</h3>
                                        </Grid>
                                        <Grid item xs={12} className="period">
                                            <span>{item.event.period}</span>
                                        </Grid>
                                        <Grid item xs={12} className="code">
                                            <label>Código de reservación</label>
                                            <span>{item.code}</span>
                                        </Grid>
                                        {/* Paquete + Asiento + Adicionales */}
                                        <Grid item xs={12} className="more">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-bus"></i>
                                                    <span>{item.seat.name}, Asiento {item.seat.seat}</span>
                                                </li>
                                                <li>
                                                    <i className="fas fa-dollar-sign"></i>
                                                    <span>{ item.package.name } <b>{ item.package.price }</b></span>
                                                </li>

                                                {/* Adicionales */}
                                                {item.additionals.map(add => {
                                                    return(
                                                        <li key={"add-"+add.name}>
                                                            <i className="fas fa-plus-circle"></i>
                                                            <span>{ add.name }</span>
                                                        </li>
                                                    )
                                                })}    
                                                
                                                {/* Travellers */}
                                                { item.travellers.length > 0 ? (
                                                    <li>
                                                        <i className="fas fa-users"></i>
                                                        <span>Viajas con: 
                                                            {item.travellers.map(traveller => {
                                                                return(<b key={UUID()} style={{ marginLeft: "10px" }}>{ traveller.firstname } { traveller.lastname }</b>)
                                                            })}  
                                                        </span>
                                                    </li>
                                                ) : (null)}                                                                                                                                   
                                            </ul>
                                        </Grid>         
                                    </Grid>
                                </Grid>
                                {/*<Grid item md={3} sm={3} xs={12} className="chart"></Grid>*/}
                            </Grid>
                        </section>

                        {/* More & Print */}
                        <section className="buttons">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Link to={"/agenda/"+item.event.slug} className={"cta black"}><i className="fas fa-info"></i> ver evento</Link>
                                </Grid>

                                <Grid item xs={6} align="right">
                                    <a href={this.props.global.API+"printBooking/"+localStorage.getItem("token")+"/"+item.event.id} target="_blank" className={"cta black"}><i className="fas fa-print"></i> imprimir</a>
                                </Grid>                                                                
                            </Grid>
                        </section>

                        {/* Detalle Colapsable */}
                        <div className={"detail "+visibility}>
                            <section className="deposits">
                                <Grid container className="top-bar">
                                    <Grid item md={9} sm={9} xs={12}>
                                        <h2>Abonos</h2>
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={12} align={align}>
                                        <Link to={"/perfil/reportar-abono/"+item.event.slug} className={"cta green"}><i className="fas fa-dollar-sign"></i> abonar</Link>
                                    </Grid>
                                </Grid>
                                {/* Deposits */}
                                {item.deposits.map(deposit => {
                                    return (
                                        <Grid key={"deposit-"+deposit.id} container className="row">
                                            <Grid item md={2} sm={2} xs={4}><span className="id">{ deposit.id }</span></Grid>
                                            <Grid item md={2} sm={2} xs={4}><span>{ deposit.date }</span></Grid>
                                            <Grid item md={2} sm={2} xs={4}><span className="reference">{ deposit.reference }</span></Grid>
                                            <Grid item md={2} sm={2} xs={6}><span className="status" style={{ color: deposit.color }}>{ deposit.status }</span></Grid>
                                            <Grid item md={2} sm={2} xs={6}><span>{ deposit.quantity }</span></Grid>
                                            <Grid item md={2} sm={2} xs={12}  align={align}>
                                                <button type="button" onClick={() => this.props.getImg(deposit.id) } className={"cta gray"}><i className="fas fa-camera"></i> detalle</button>
                                            </Grid>
                                        </Grid>
                                    )
                                })}  
                            </section>

                            {/* TOTAL */}
                            <section>
                                <Grid container justify={"flex-end"}>
                                    <Grid item xs={12} align={"right"}  className={"total"}>
                                        <span>Total abonado:</span>
                                        <b>{ item.abonado }</b>
                                    </Grid>
                                </Grid>
                            </section>

                            {/* Camiones
                            <hr/>
                            <section className="seats">
                                <Grid container className="top-bar">
                                    <Grid item md={3}>
                                        <h2>asientos</h2>
                                    </Grid>
                                    <Grid item md={9}>
                                        {item.buses.map(bus => {
                                            return (<Btn bus={bus} key={"bus-"+bus.id} userseat={item.seat} />)
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {item.buses.map((bus, index) => {
                                            return(<Bus event={item.event} key={"bus-layout-"+bus.id+"-"+index} bus={bus} userseat={item.seat} />)
                                        })}                                   
                                    </Grid>                                                           
                                </Grid>
                            </section>
                            <section>
                                <span className="disclaimer">*El orden y la distribución de asientos puede cambiar sin previo aviso.</span>
                            </section>
                             */}
                            
                        </div>
                   </div> 
                </Grid>

                {/* Imagen del Abono */}
                <Img/>
            </Grid>
        
        
            
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setToggle,
        getImg
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Item); 