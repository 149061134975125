export const agenda = (state = {
	events: false,
	event: false,
	additionals: false,
	loadingads: false,
	selectedPackage: false,
	selectedAdditionals: [],
	total: false,
	loadingtotal: false
}, action) => {
	switch(action.type)
	{
		case "SET-EVENTS":
			state = {
				...state,
				events : action.payload
			};
		break;	

		case "SET-EVENT":
			state = {
				...state,
				event : action.payload
			};
		break;	


		case "SET-ADDITIONALS":
			state = {
				...state,
				additionals : action.payload
			};
		break;	


		case "SET-LOADING-ADS":
			state = {
				...state,
				loadingads : action.payload
			};
		break;


		case "SET-SELECTED-PACKAGE":
			state = {
				...state,
				selectedPackage : action.payload
			};
		break;	


		case "SET-SELECTED-ADDITIONALS":
			state = {
				...state,
				selectedAdditionals : action.payload
			};
		break;	


		case "SET-LOADING-TOTAL":
			state = {
				...state,
				loadingtotal : action.payload
			};
		break;


		case "SET-TOTAL":
			state = {
				...state,
				total : action.payload
			};
		break;						

		default:
			return state;
	}

	return state
};