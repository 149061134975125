import React from 'react';
import compose from 'recompose/compose';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


// Components =>
import Footer from './Footer';
import Header from './Header';
import HeaderMobile from './HeaderMobile';

// Actions
import {updateWindowWidth} from './Actions'

// Router:
import { withRouter } from 'react-router-dom'

// Helper:
import {windowScroll} from "../../Utilities/Helpers";


/****************************************
    Return Render =>
****************************************/
class Layout extends React.Component {
    
    componentWillMount(){
        var props = this.props;
        window.onresize = function(event) {
            props.updateWindowWidth();
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        windowScroll(0,0);
    }


    render() {
        const vars = this.props.global.variables;
        return (
            <React.Fragment>
                {(this.props.global.windowwidth <= 959) ? <HeaderMobile/> : <Header/>}
                <main>
                    {this.props.children}

                    {vars.whatsapp !== undefined ? 
                        <a target="_blank" rel="noopener noreferrer" href={"https://wa.me/"+vars.whatsapp} className="whatsapp-plugin">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                    : null }
                </main>
                <Footer/> 
            </React.Fragment>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        updateWindowWidth
    }, dispatch);
}

export default compose(
    withRouter,
    connect(mapStateToProps, matchDispatchToProps)
)(Layout);