// Obtiene los eventos de la agenda =>
export function getEvents() {
    return (dispatch, getState) => {
    	const state = getState(); 	
    	const request = fetch(`${state.global.API}getEvents`);
	    request.then(res => res.json()).then(events => {
			dispatch({
				type 	: "SET-EVENTS", 
				payload	: events
			});
	    })    	
    }
}



// Obtiene la información de un evento por su slug =>
export function eventInfo(slug) {
    return (dispatch, getState) => {

		return new Promise((resolve, reject) => {
			
			const state = getState();
			const request = fetch(`${state.global.API}eventInfo/${slug}`);

			dispatch({
				type 	: "SET-EVENT", 
				payload	: false
			});

			dispatch({
				type 	: "SET-SELECTED-PACKAGE", 
				payload	: false
			});	
			dispatch({
				type 	: "SET-SELECTED-ADDITIONALS", 
				payload	: []
			});
			dispatch({
				type 	: "SET-ADDITIONALS", 
				payload	: false
			});

			dispatch({
				type 	: "SET-LOADING-ADS", 
				payload	: false
			});		

			dispatch({
				type 	: "SET-TOTAL", 
				payload	: false
			});	

			dispatch({
				type 	: "SET-LOADING-TOTAL", 
				payload	: false
			});				
			request.then(res => res.json()).then(event => {
				dispatch({
					type 	: "SET-EVENT", 
					payload	: event
				});
				window.scrollTo(0, 240);
				resolve(event);
			})	
		});
    }
}



// Obtiene los adicionales de un paquete =>
export function getAdditionals(id) {
    return (dispatch, getState) => {
    	const state = getState();
		dispatch({
			type 	: "SET-ADDITIONALS", 
			payload	: false
		});

		dispatch({
			type 	: "SET-SELECTED-PACKAGE", 
			payload	: id
		});	

		dispatch({
			type 	: "SET-SELECTED-ADDITIONALS", 
			payload	: []
		});

		dispatch({
			type 	: "SET-LOADING-ADS", 
			payload	: true
		});

		dispatch({
			type 	: "SET-LOADING-TOTAL", 
			payload	: true
		});		

		const request = fetch(`${state.global.API}getAdditionals/${id}`);
	    request.then(res => res.json()).then(additionals => {
			dispatch({
				type 	: "SET-ADDITIONALS", 
				payload	: additionals
			});

			// Recalculate Costs =>
			dispatch(clearTotal());
			dispatch(recalculateCost());
	    })
    }
}



// Administra el obj de adicionales seleccionados =>
export function objAdditionals(id) {	
	return (dispatch, getState) => {

		const state = getState();

		var selected = state.agenda.selectedAdditionals;
		if(Object.values(selected).indexOf(id) !== -1)
		{
		    for(var f in selected) {
		        if(selected[f] === id) {
		            selected.splice(f,1);
		        }
		    }
		}
		else
		{
			selected.push(id);
		}

		if(selected === 'empty'){selected = [];}
		dispatch({
			type 	: "SET-SELECTED-ADDITIONALS", 
			payload	: selected
		});

		dispatch(clearTotal());
		dispatch(recalculateCost());
	}
}



// Clear Total
export function clearTotal(){
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-TOTAL", 
			payload	: false
		});			
	}
}


// Recalcula el costo de lo seleccionado =>
export function recalculateCost(){
	return (dispatch, getState) => {
		const state = getState();
		var params  = '?';
		params += 'package='+state.agenda.selectedPackage;

		// Agrega los adicionales
		state.agenda.selectedAdditionals.map((ad) =>
			params += '&additional[]='+ad
		)

		const request = fetch(`${state.global.API}recalculateCost${params}`);
		request.then(res => res.json()).then(res => {
			dispatch({
				type 	: "SET-TOTAL", 
				payload	: res.total
			});
		})
	}
}