import update from 'react-addons-update';

export const misviajes = (state = {
	upcoming: false,		// Mis próximos viajes
	seatassigned: false     // True/False modal de asiento asignado
}, action) => {
	switch(action.type)
	{
		case "SET-UPCOMING-EVENTS":
			state = {
				...state,
				upcoming : action.payload
			};
		break;	

		case 'SET-SEAT-ASSIGNED-MODAL':
			state = {
				...state,
				seatassigned : action.payload
			};
		break;			
		
		case 'SET-TOGGLE':
			state = update(state, { 
			    upcoming: { 
			    	[action.payload.index] : {
						event : {
							toggle: {$set: action.payload.toggle}
						}
					}
			    }
		  	});
		break;	


		default:
			return state;
	}

	return state
};