import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {logIn,modalForgot} from './Actions';

// Componetns =>

/****************************************
    Return Render =>
****************************************/
class Login extends React.Component {
    render() {
        //const { classes } = this.props;
        return (
			<div>
				<h2><i className="fas fa-lock"></i> Iniciar Sesión</h2>        	
	            <form onSubmit={(e) => {this.props.logIn(e); e.preventDefault();}}>
	                <Grid container>
	                    <Grid item md={12} sm={12} xs={12}>
	                        <label>Usuario:</label>
	                        <input type="text" name="email" placeholder="E-mail:" autoComplete="off" />
	                    </Grid>
	                    <Grid item md={12} sm={12} xs={12}>
	                        <label>Contraseña:</label>
	                        <input type="password" name="pass" placeholder="Contraseña:" autoComplete="off" />
	                    </Grid>

	                    <Grid item md={6} sm={6} xs={6}>
							{this.props.acceso.isloging ? (
	                        	<CircularProgress style={{ color: '#00a550', marginLeft:"20px" }} />
							) : (
								<button className="cta green" type="submit"><i className="fas fa-lock"></i> Ingresar</button>
							)}
	                    </Grid>
	                    <Grid item md={6} sm={6} xs={6} align="right">
	                    	<button type="button" style={{ marginTop: '10px'}} className="link-more" onClick={() => this.props.modalForgot(true) }>Olvidé mi contraseña.</button>
	                    </Grid>

						{/* <hr/> */}

						<Grid item xs={12} align="center">
							{/*<div class="fb-login-button" data-max-rows="1" data-size="small" data-button-type="continue_with" data-show-faces="false" data-auto-logout-link="false" data-use-continue-as="true"></div>*/}
						</Grid>

	                </Grid>                                
	            </form>
	        </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        logIn,
        modalForgot
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Login); 