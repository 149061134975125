import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {isOut} from './Actions';

// Router
import { NavLink } from 'react-router-dom';

/****************************************
    Return Render =>
****************************************/
class Out extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                fullScreen={classes}
                open={this.props.global.isout}
                onClose={() => this.props.isOut(false)}
                aria-labelledby="responsive-dialog-title"
                className="mit-dialog"
            >
                <DialogTitle>Se requiere una sesión de usuario.</DialogTitle>
                <DialogContent className="dialog-content">
                    <i className="fas fa-lock" style={{color:'#111'}}></i>
                    <DialogContentText>
                        Para poder apartar tu lugar con abono, realizar el registro de depósitos, ver el estatus de tus viajes, etc. Es necesario iniciar una sesión de usuario en el sitio.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <NavLink to={"/acceso"} onClick={() => this.props.isOut(false)} className={"cta gray"}><i className="fas fa-lock"></i> Iniciar sesión</NavLink>
                    <NavLink to={"/acceso"} onClick={() => this.props.isOut(false)} className={"cta green"}><i className="fas fa-user"></i> Crear una cuenta</NavLink>
                </DialogActions>
            </Dialog>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        isOut
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Out); 