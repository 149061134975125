import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {setModalImg} from './Actions';


/****************************************
    Return Render =>
****************************************/
class Img extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
				<Dialog
					fullScreen={classes}
					open={this.props.notifications.imgmodal}
					onClose={() => this.props.setModalImg()}
					aria-labelledby="responsive-dialog-title"
					className="mit-dialog"
				>
					<DialogTitle>
						Imagen del Abono
					</DialogTitle>
					<DialogContent className="dialog-content">
						<DialogContentText>
							<img src={this.props.notifications.imgsrc || "/img/general/placeholder.png"} alt="Imagen del abono" width="100%" />
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<button type="button" className={"cta gray"} onClick={() => this.props.setModalImg() }><i className="fas fa-times"></i> cerrar</button>
					</DialogActions>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setModalImg
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Img); 