import {API} from "../../Utilities/Helpers";

// Obtiene los contadores de la nevegación izquierda: cuántas notificaciones, cuántos próximos eventos, etc...
export function getCounters() {
    return (dispatch, getState) => {
		const state = getState(); 	
		var request = API(`${state.global.API}getCounters`, 'GET');
	    request.then(res => res.json()).then(json => {
			dispatch({
				type 	: "SET-COUNTERS", 
				payload	: json
			});
	    })    	
    }
}




// Valida la contraseña actual:
export function validatePassword(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});
		
		var state    = getState();
		var formData = new FormData(e.target);
		var request  = API(`${state.global.API}validatePassword`, "POST", formData);

		request.then(res => res.json()).then(errors => {
			if(errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: errors[0] // Mostramos el primero error en el nackbar
				});				
			}
			else
			{
				dispatch({
					type 	: "SET-PASSWORD-CHANGE-ALLOWED", 
					payload	: true
				});
			}

			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
		});
	};
}





// Cambia la contraseña del cliente:
export function setPassword(e) {
	return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SUBMITING", 
			payload	: true
		});
		
		var state    = getState();
		var formData = new FormData(e.target);
		var request  = API(`${state.global.API}setPassword`, "POST", formData);

		request.then(res => res.json()).then(errors => {
			if(errors.length > 0)
			{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: errors[0] // Mostramos el primero error en el nackbar
				});				
			}
			else
			{
				dispatch({
					type 	: "SET-DIALOG-PASSWORD", 
					payload	: false
				});
				dispatch({
					type 	: "SET-DIALOG-PASSWORD-UPDATED", 
					payload	: true
				});				
			}

			dispatch({
				type 	: "SET-SUBMITING", 
				payload	: false
			});
		});
	};
}