export function resetVars() {
	return (dispatch) => {
		dispatch({
			type 	: "SET-HISTORY-EVENTS", 
			payload	: false
		});
	};
}




// Obtiene las notificaciones del perfil del usuario:
export function getHistory() {
    return (dispatch, getState) => {
    	const state = getState(); 	
    	const request = fetch(`${state.global.API}getHistory`, {headers: state.global.fetchHeaders });
	    request.then(res => res.json()).then(items => {
			dispatch({
				type 	: "SET-HISTORY-EVENTS", 
				payload	: items
			});
	    })    	
    }
}