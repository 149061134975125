// Helpers
import {API} from '../Utilities/Helpers';
import {getCounters} from "../Layout/Private/Actions";


// Obtiene las notificaciones del perfil del usuario:
export function getNotifications() {
    return (dispatch, getState) => {
		const state = getState(); 	

		dispatch({
			type 	: "SET-NOTIFICATIONS", 
			payload	: false
		});


		var request = API(`${state.global.API}getNotifications`, 'GET');
	    request.then(res => res.json()).then(items => {
			dispatch({
				type 	: "SET-NOTIFICATIONS", 
				payload	: items
			});

			// Descarta las notificaciones que se acaban de ver...
			dispatch(getCounters());
	    })    	
    }
}




// Obtiene la imagen
export function getImg(id) {
    return (dispatch, getState) => {
		const state = getState(); 	
		var request = API(`${state.global.API}getImg/${id}`, 'GET');
	    request.then(res => res.json()).then(res => {
			dispatch({
				type 	: "SET-IMG-SRC", 
				payload	: res.img
			});
			dispatch(setModalImg());
	    })    	
    }
}





// Cierra el modal con la imagen
export function setModalImg() {
    return (dispatch, getState) => {
    	var state = getState();
    	var status = state.notifications.imgmodal;
		dispatch({
			type 	: "SET-MODAL-IMG", 
			payload	: !status
		});	
    }
}




// Cierra el modal con la imagen
export function discardNotification(id) {
    return (dispatch, getState) => {
    	const state = getState();
		var request = API(`${state.global.API}discardNotification/${id}`, 'GET');
	    request.then(res => res.json()).then(res => {
			dispatch(getNotifications());
	    })    	
    }
}


