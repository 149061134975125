import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// CSS
import '../chat.css';

// Actions =>
import {resetVars, getThreads, getMessages, sendMessage} from './Actions';

// Componetns =>
import CircularProgress from '@material-ui/core/CircularProgress';
import Wrapper from './Wrapper';

/****************************************
    Return Render =>
****************************************/
class Mensajes extends React.Component {

    componentWillMount(){
        this.props.resetVars();
        this.props.getThreads();
        this.props.getMessages();
    }


    theMessages(){
        if(this.props.messages.messages.length === 0)
        {
            return(
                <div className="empty-chat" style={{ marginTop: '100px'}}>
                    <i className="fas fa-comments"></i>
                </div>
            );           
        }
        else
        {
            return(
                <div>
                    <ol className="chat">
                        {this.props.messages.messages.map(function(message, index){
                            if(message.created_by_id)
                            {
                                return(
                                    <li className="other" key={"message-"+message.id}>
                                        <div className="avatar">
                                            <img src="//admin.mar-iguanatours.com.mx/app/default/assets/addons/default/anduryn/site-theme/resources/img/logo.png" alt="MIT LOGO"/>
                                        </div>
                                        <div className="msg">
                                            <b>{ message.author }</b>
                                            <p>{ message.message }</p>
                                            <time>{ message.date }</time>
                                        </div>
                                    </li>
                                )
                            }
                            else
                            {
                                return(
                                    <li className="self" key={"message-"+message.id}>
                                        <div className="avatar">
                                            <img src="//admin.mar-iguanatours.com.mx/app/default/assets/addons/default/anduryn/events-module/resources/img/chat-user.png?v=1522477464" alt="USER AVATAR"/>
                                        </div>
                                        <div className="msg">
                                            <b>{ message.author }</b>
                                            <p>{ message.message }</p>
                                            <time>{ message.date }</time>
                                        </div>
                                    </li>
                                )
                            }               
                        })}                                                                                                                                    
                    </ol>
                    {!this.props.messages.active ? (
                        <form onSubmit={(e) => {this.props.sendMessage(e); e.preventDefault();}} className="text-area">
                            <textarea placeholder="Escribir mensaje:" name="message"></textarea>
                            {!this.props.global.issubmiting ? (
                                <button type="submit" className="cta green">
                                    <i className="far fa-paper-plane"></i>
                                </button>
                            ) : (
                                <button className="cta green">
                                    <i className="fas fa-circle-notch fa-spin"></i>
                                </button>
                            )}
                        </form>
                    ) : (null)}
                </div>
            );    
        }

    }

    render() {

        if(this.props.messages.threads === false)
        {
            return(<Wrapper>
                <Grid container justify="center">
                    <Grid item xs={12} align="center">
                        <CircularProgress style={{ color: "green" }} thickness={1.5}/>
                    </Grid>
                </Grid>
            </Wrapper>)
        }
        else
        {
            const that = this.props;
            const active = this.props.messages.active;
            return (
                <div id="mensajes">
                    <div className="breakpoint">
                        <Grid container spacing={24}>
                            <Grid item md={12} sm={12} xs={12}>
                                <h1><i className="fas fa-comments"></i> Mis mensajes</h1>
                            </Grid>
    
    
                            <Grid item lg={3} md={4} sm={4} xs={12}>
                                <nav>
                                    <h1>Conversaciones</h1>
                                    <div onClick={() => { that.getMessages(); }} className={(active === false ? 'aconv active' : 'aconv')}>
                                        <i className="fas fa-lock"></i> 
                                        Mensajes privados 
                                        {this.props.global.counters.chat > 0 ? (
                                            <span>{this.props.global.counters.chat}</span>
                                        ) : (null)}
                                    </div>
                                    {this.props.messages.threads.map(function(thread, index){
                                        return(<div
                                                onClick={() => { that.getMessages(thread.id); }} 
                                                key={"thread-"+index}
                                                className={(active === thread.id ? 'aconv active' : 'aconv')}
                                               >
                                                    <i className="fas fa-calendar-alt"></i> 
                                                    {thread.name}
                                                    {thread.unread > 0 ? (
                                                        <span>{ thread.unread }</span>
                                                    ) : (null)}
                                               </div>)                      
                                    })}
                                </nav>
                            </Grid>
    
    
                            <Grid item lg={9} md={8} sm={8} xs={12}>
                                <div className="chat-tab">
                                    {!this.props.messages.messages ? (
                                        <Grid container justify="center">
                                            <Grid item xs={12} align="center">
                                                <CircularProgress style={{ color: "green", marginTop: '100px' }} thickness={1.5}/>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        this.theMessages()
                                    )}
                                </div>
                            
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }


    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        resetVars,
        getThreads, 
        getMessages,
        sendMessage
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Mensajes); 