export const acceso = (state = {
	isloging: false,  // Determina si el formulario de Login está siendo procesado
	welcome: false,
	forgot: false,
	passsent: false,
	registered: false,
}, action) => {
	switch(action.type)
	{
		case "SET-LOGING":
			state = {
				...state,
				isloging : action.payload
			};
		break;	

		case "MODAL-WELCOME":
			state = {
				...state,
				welcome : action.payload
			};
		break;

		case "SET-MODAL-FORGOT":
			state = {
				...state,
				forgot : action.payload
			};
		break;	

		case "SET-PASSWORD-SENT":
			state = {
				...state,
				passsent : action.payload
			};
		break;		

		case "SET-MODAL-REGISTERED":
			state = {
				...state,
				registered : action.payload
			};
		break;						

		default:
			return state;
	}

	return state
};