export const conocenos = (state = {
	info: false,
}, action) => {
	switch(action.type)
	{
		case "SET-CONOCENOS":
			state = {
				...state,
				info : action.payload
			};
        break;
        
		default:
			return state;
	}

	return state
};