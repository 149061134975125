import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {setResume,anotherDeposit,updateTerms,saveDeposit} from './Actions';
import {dialogTerms} from '../Utilities/Actions';


// Componetns =>
import Wrapper from '../Utilities/Wrapper';


/****************************************
    Return Render =>
****************************************/
class Resume extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cssfixed:''};
        this.handleScroll = this.handleScroll.bind(this);
    }


    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
        window.onresize = this.handleScroll;
    }

    // Resume Position Fixed vs Inherit
    handleScroll() {
        let cssfixed = '';
        if(window.innerWidth >= 1280){
            if(document.documentElement.scrollTop > 337)
            {
                cssfixed = 'fixed';
            }
            if(this.state.cssfixed !== cssfixed)
            {
                this.setState({'cssfixed' : cssfixed});
            }
        } else {

            
            this.setState({'cssfixed' : ''});
        }
    }



    // Filas del Resumen
    resumeRows(){
        let rows = [];

        if(this.props.abono.info.isnew )
        {
            for(var i = 1; i <= this.props.abono.counter; i++)
            {
                var name = (this.props.abono.firstname[i] !== '') ? this.props.abono.firstname[i]+' '+this.props.abono.lastname[i] : 'Persona '+i;
                rows.push(
                    <Grid key={"resume-row-"+i} container className="td">
                        <Grid item xs={6}>
                            <label>{name}</label>
                        </Grid>
                        <Grid item xs={3}>
                            <span>{this.props.abono.cost[i]}</span>
                        </Grid>
                        <Grid item xs={3} align="right">
                            <b>$0.00</b>
                        </Grid>
                    </Grid>  
                );
            }
        }

        else
        {
            this.props.abono.info.travellers.map((traveller, index) => {
                rows.push(
                    <Grid key={"resume-row-"+index} container className="td">
                        <Grid item xs={6}>
                            <label>{traveller.firstname} {traveller.lastname}</label>
                        </Grid>
                        <Grid item xs={3}>
                            <span>${traveller.total}</span>
                        </Grid>
                        <Grid item xs={3} align="right">
                            <b>${traveller.abonado}</b>
                        </Grid>
                    </Grid>  
                );
                return true;
            });
        }

        return rows;
    }

    render() {
        //const { classes } = this.props;
        const total  = (this.props.abono.info.isnew) ? this.props.abono.total : this.props.abono.info.total;
        const abonado = (this.props.abono.info.isnew) ? this.props.abono.abonado : this.props.abono.info.abonado;
        const adeudo = (this.props.abono.info.isnew) ? this.props.abono.total : this.props.abono.info.adeudo;
        //this.props.abono.resume <= esto estaba en la clase (why?)
        return (<div className={"resume-box "+this.state.cssfixed}>
                <h2 onClick={() => this.props.setResume() }>Resúmen</h2>

                    <Grid container className="th">
                        <Grid item xs={6}>Reservación</Grid>
                        <Grid item xs={3}>Costo</Grid>
                        <Grid item xs={3}>Abonado</Grid>
                    </Grid>

                    {/* Resume Rows */}
                    { this.resumeRows() }

                    {/* Ribbon */}
                    <div className="ribbon">
                        <div className="floater">
                            <div className="total">
                                <Grid container className="td">
                                    <Grid item xs={6}>
                                        <label>Total</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span>{ total }</span>
                                    </Grid>
                                    <Grid item xs={3} align="right">
                                        <b>{ abonado }</b>
                                    </Grid>
                                </Grid>  
                            </div>
                            <div className="adeudo">
                                 <Grid container className="td">
                                    <Grid item xs={9}>
                                        <label>Saldo pendiente</label>
                                    </Grid>
                                    <Grid item xs={3} align="right">
                                        <b>{ adeudo }</b>
                                    </Grid>
                                </Grid>  
                            </div>  
                        </div>                              
                    </div>

                    {/* Terms & Conditions */}
                    <Grid container className="chk">
                        <Grid item xs={12}>
                            <label className="has-check">
                                <input type="checkbox" onChange={(e) => this.props.updateTerms(e) }/>
                                <span>He leído y acepto los</span> <b onClick={() => this.props.dialogTerms(true) }>términos y condiciones</b>
                            </label>
                        </Grid>
                    </Grid>

                    {/* Button */}
                    <Grid container>
                        <Grid item xs={12} align="center">
                            <Wrapper issubmiting={this.props.global.issubmiting} color={"#fff"}>
                                {this.props.abono.info.isnew ? (
                                    <button className={"cta yellow"} onClick={() => this.props.saveDeposit() }>Enviar Deposito</button>
                                ) : (
                                    <button className={"cta yellow"} onClick={() => this.props.anotherDeposit() }>Enviar Deposito</button>
                                )}
                            </Wrapper>
                        </Grid>
                    </Grid>
                </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setResume,
        anotherDeposit,
        updateTerms,
        saveDeposit,
        dialogTerms
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Resume); 