import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {closeSeatAssignedModal} from './Actions';


/****************************************
    Return Render =>
****************************************/
class SeatAssigned extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
				<Dialog
					fullScreen={classes}
					open={this.props.misviajes.seatassigned}
					onClose={() => this.props.closeSeatAssignedModal()}
					aria-labelledby="responsive-dialog-title"
					className="mit-dialog"
				>
					<DialogTitle>
						Asiento seleccionado correctamente
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i className="far fa-check-circle"></i>
						<DialogContentText>
							<b>{this.props.global.user.displayname}</b> tu selección de asiento ha sido actualizada correctamente.
							<br/>
							Tu nuevo asiento es <b>Asiento #{this.props.misviajes.aseat}</b>
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<button type="button" onClick={() => this.props.closeSeatAssignedModal() } className={"cta gray"}>cerrar</button>
					</DialogActions>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        closeSeatAssignedModal
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(SeatAssigned); 