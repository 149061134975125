export function initVars() {
	window.scrollTo(0, 0);
	return (dispatch, getState) => {
	dispatch({
		type 	: "SET-BLOGS", 
		payload	: false
	});

	dispatch({
		type 	: "SET-BLOG-POST", 
		payload	: false
	});
	}
}




export function getPosts() {
    return (dispatch, getState) => {
    	setTimeout(function(){
	    	var state = getState();
	    	const request = fetch(`${state.global.API}getPosts`);
		    request.then(res => res.json()).then(items => {
				dispatch({
					type 	: "SET-BLOGS", 
					payload	: items
				});
		    });
		},500)	
    }
}


export function getPost() {
    return (dispatch, getState) => {
    	var state = getState();
    	var url  = document.URL.split('/');
    	var slug = url[(url.length - 1)];

    	const request = fetch(`${state.global.API}getPost/${slug}`);
	    request.then(res => res.json()).then(post => {
			dispatch({
				type 	: "SET-BLOG-POST", 
				payload	: post
			});
	    })    	
    }
}