import {combineReducers} from 'redux';

import {global} from './global-state';
import {agenda} from './Agenda/Reducer';
import {destinos} from './Destinos/Reducer';
import {acceso} from './Acceso/Reducer';
import {notifications} from './Notificaciones/Reducer';
import {misviajes} from './Misviajes/Reducer';
import {profile} from './Perfil/Reducer';
import {historial} from './Historial/Reducer';
import {abono} from './Abonos/Reducer';
import {contacto} from './Contacto/Reducer';
import {blogs} from './Blog/Reducer';
import {home} from './Home/Reducer';
import {conocenos} from './Conocenos/Reducer';
import {messages} from './Mensajes/Reducer';

// Combine Them All! =>
const all = combineReducers({
	global,
	home,
	agenda,
	conocenos,
	destinos,
	acceso,
	notifications,
	misviajes,
	profile,
	historial,
	abono,
	contacto,
	blogs,
	messages
});

export default all;