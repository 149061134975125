import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {thanksModal} from './Actions';


/****************************************
    Return Render =>
****************************************/
class Thanks extends React.Component {
    render() {
        return (
            <div>
				<Dialog
					open={this.props.contacto.thanks}
					onClose={() => this.props.thanksModal()}
					className="mit-dialog"
				>
					<DialogTitle>
						¡Gracias por contactarnos!
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i className="far fa-check-circle"></i>
						<DialogContentText>
							Hemos recibido tus comentarios correctamente, en breve nos pondremos en contacto para aclarar cualquier duda o comentario.
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<button type="button" onClick={() => this.props.thanksModal() } className={"cta gray"}>cerrar</button>
					</DialogActions>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        thanksModal
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Thanks); 