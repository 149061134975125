import React from 'react';
import compose from 'recompose/compose';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components => 
import Agenda from '../Agenda/Agenda';
import Blog from '../Blog/Blog';
import Activating from './Activating';

import {getHome} from './Actions';


/****************************************
    Return Render =>
****************************************/
class Home extends React.Component {

    componentDidMount(){
        this.props.getHome();

        document.loop = 0;
        document.bgLoop = setInterval(() => {
            var banners = document.getElementsByClassName("banner-item");
            for (let item of banners) {
                item.style["background-image"] = 'url("'+item.title+'")';
            }
            document.loop++;
            if(document.loop === 15){
                clearInterval(document.bgLoop);
            }
        }, 500);
    }


    switchBannersView(){
        var {banners} = this.props.home;
    	// Se muestra un bloque diferente de acuerdo al valor del state "confirmatio":
    	if(banners)
    	{
    		return(
                <Carousel className="banner-holder" stopOnHover={true} transitionTime={1000} emulateTouch={true} autoPlay={true} interval={5000} showThumbs={false} infiniteLoop={true} showStatus={false} showIndicators={false}>
                    {banners.map((banner, i) => {
                        return(
                            <div className="banner-item" key={"banner-"+banner.id} style={{backgroundImage: 'url('+banner.banner+') !important'}} title={banner.banner}>
                                <div className="banner-text">
                                    <h2>{banner.titulo}</h2>
                                    <span>{banner.fecha}</span>
                                    <h3>{banner.subtitulo}</h3>
                                    <a href={banner.link} className="cta green">Más información</a>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
    		);
        }
        else
        {
            return(<div className="banner-holder"> </div>);
        }
    }



    render() {
        //const { classes } = this.props;
        return (
            <div id="home">
                {/* BANNERS */}
                { this.switchBannersView() }
                {/* AGENDA */}
                <Agenda/>
                {/* BLOGS */}
                <Blog/>
                {/* Activación del Registro */}
                <Activating/>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getHome
    }, dispatch);
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps)
)(Home);