import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {modalRegistered} from './Actions';


/****************************************
    Return Render =>
****************************************/
class Registered extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
				<Dialog
					fullScreen={classes}
					open={this.props.acceso.registered}
					onClose={() => this.props.modalRegistered()}
					aria-labelledby="responsive-dialog-title"
					className="mit-dialog"
				>
					<DialogTitle>
						¡Gracias por registrarte!
					</DialogTitle>
					<DialogContent className="dialog-content">
						<i style={{color:'#111'}} className="far fa-envelope"></i>
						<DialogContentText>
							<p style={{color:'#111'}}>Para <b>completar el proceso</b> sólo es necesario confirmar tu registro siguiendo las <b>instrucciones que te hemos enviado a tu cuenta de correo</b>.</p>
						</DialogContentText>
					</DialogContent>
					<DialogActions className="dialog-actions">
						<button type="button" className={"cta gray"} onClick={() => this.props.modalRegistered() }><i className="fas fa-mouse-pointer"></i> cerrar</button>
					</DialogActions>
				</Dialog>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        modalRegistered
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Registered); 