import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {loggedOut} from './Actions';

// Router
import { NavLink } from 'react-router-dom';

/****************************************
    Return Render =>
****************************************/
class Out extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                fullScreen={classes}
                open={this.props.global.loggedout}
                onClose={() => this.props.loggedOut(false)}
                aria-labelledby="responsive-dialog-title"
                className="mit-dialog"
            >
                <DialogTitle>Se ha concluído tu sesión.</DialogTitle>
                <DialogContent className="dialog-content">
                    <i className="fas fa-lock" style={{color:'#111'}}></i>
                    <DialogContentText>
                        Tu sesión de usuario ha sido terminada exitósamente, puedes volver en cualquier momento.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <button type="button" onClick={() => this.props.loggedOut(false)} className={"cta gray"}><i className="fas fa-lock"></i> cerrar</button>
                    <NavLink to={"/acceso"} onClick={() => this.props.loggedOut(false)} className={"cta green"}><i className="fas fa-user"></i> Iniciar sesión</NavLink>
                </DialogActions>
            </Dialog>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        loggedOut
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Out); 