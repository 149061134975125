import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';

// Redux =>
//import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

/****************************************
    Return Render =>
****************************************/
class Wrapper extends React.Component {
    render() {
        return (
            <div id="misviajes">
                <Grid container>
                    <Grid item xs={12}>
                        <h1><i className="fas fa-comments"></i> Mis mensajes</h1>
            	        {this.props.children}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


export default compose(
    connect(mapStateToProps, null)
)(Wrapper); 