import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {getPosts, initVars, getPost} from './Actions';


// With Styles =>
const styles = theme => {
    return {
        progress : {
            margin: '0 auto',
            color: '#00a550',
            marginTop: '40px',
            marginBottom: '40px',
        }
    };
};



/****************************************
    Return Render =>
****************************************/
class Blog extends React.Component {
    
    componentDidMount(){
        this.props.getPosts();
    }

    render() {
        const { classes } = this.props;
        return (
            <div id="blog">
                <div className={"breakpoint"}>
                    
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                           <h1>Blog</h1>
                        </Grid>
                    </Grid>

                    <Grid container spacing={24}>

                        {this.props.blogs.blogs ? (
                            this.props.blogs.blogs.map(post => {
                                return (                                          
                                    <Grid item key={post.slug} md={3} sm={6} xs={12} className={"blog-item"}>
                                        <a href={"/blog/"+post.slug}>
                                            <div className={"img-holder"}>
                                                <img src={post.imagen} alt={post.imagen} />
                                            </div>
                                            <h2>{ post.title }</h2>
                                            <label>Por { post.author }</label>
                                            <small>{ post.publish_at }</small>
                                        </a>
                                    </Grid>  
                                );
                            })
                        ) : (
                            <Grid container justify="center">
                                <Grid item xs={12} align={"center"}>
                                    <CircularProgress className={classes.progress}/>
                                </Grid>
                            </Grid>
                        )}                                         
                    </Grid>
                </div>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getPosts,
        initVars,
        getPost
    }, dispatch);
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, matchDispatchToProps)
)(Blog);