import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {closeThankyou,eventDepositsInfo, initDepositVars} from './Actions';

import { Link } from 'react-router-dom'


/****************************************
    Return Render =>
****************************************/
class Thankyou extends React.Component {
   

    // Will Mount =>
    componentDidMount(){
    	var props = this.props;
     	
     	setTimeout(function(){
            props.initDepositVars();
            var spl = document.URL.split('/');
            var slug = spl[spl.length-1];
            props.eventDepositsInfo(slug);
            props.closeThankyou();
            window.location.href='/perfil';
     	},30000);
    }

    render() {
        return (
            <div id="abono">
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className="yellow-box">
	        				<h2>¡Gracias por realizar el registro de tu depósito!</h2>

	        				<i style={{ diplay:'inline-block', color:'green', fontSize:'32px'}} className="far fa-check-circle"></i>
	        				<br/>

	        				<p>En un plazo <strong>no mayor a 48 horas</strong> validaremos la información que nos has proporcionado.</p>
	    					<p><b>Para modificar datos en su reservación</b>, favor de comunicarse a las oficinas de <br/> Mar Iguana Tours al teléfono: <b>(33) 2015 9141</b></p>
                        
	    					<Link to={"/perfil"} className="cta black">Volver a inicio</Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        eventDepositsInfo,
        closeThankyou,
        initDepositVars
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Thankyou); 