import update from 'react-addons-update';

// Slug=>
var spl = document.URL.split('/');
var slug = spl[spl.length-1];

export const abono = (state = {
	thankyou: false,
	slug: slug,
	info: false,
	counter: 1, 		// <= The booking number
	total: '$0.00',
	abonado: '$0.00',
	pendiente: '$0.00',
	quantity: '',
	accepted: false,
	image: false,
	src: "",
	reference: '',
	comments: '',
	resume: '',
	positions: ['','','','',''],
	split: {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},
	firstname : {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},
	lastname : {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},	
	email : {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},
	phone : {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},
	gender : {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},
	tshirt : {
		1 : '',
		2 : '',
		3 : '',
		4 : '',
		5 : ''
	},				
	bus  : {
		1 : false,
		2 : false,
		3 : false,
		4 : false,
		5 : false
	},
	pack : {
		1 : false,
		2 : false,
		3 : false,
		4 : false,
		5 : false
	},
	seat : {
		1 : false,
		2 : false,
		3 : false,
		4 : false,
		5 : false
	},
	add  : {
		1 : {1 : false,2 : false,3 : false,4 : false,5 : false},
		2 : {1 : false,2 : false,3 : false,4 : false,5 : false},
		3 : {1 : false,2 : false,3 : false,4 : false,5 : false},
		4 : {1 : false,2 : false,3 : false,4 : false,5 : false},
		5 : {1 : false,2 : false,3 : false,4 : false,5 : false}
	},
	cost : {
		1 : '$0.00',
		2 : '$0.00',
		3 : '$0.00',
		4 : '$0.00',
		5 : '$0.00'
	},
	abonos : {
		1 : '$0.00',
		2 : '$0.00',
		3 : '$0.00',
		4 : '$0.00',
		5 : '$0.00'
	},
	debt : {
		1 : '$0.00',
		2 : '$0.00',
		3 : '$0.00',
		4 : '$0.00',
		5 : '$0.00'
	}	
}, action) => {
	switch(action.type)
	{
		case "RESET-DEPOSIT-STATES":
			state = update(state, {$set: action.payload});
		break;



		case "SET-EVENT-DEPOSITS-INFO":
			state = {
				...state,
				info : action.payload
			};
		break;


		case 'SET-BOOKING-NUMBER':
			state = {
				...state,
				counter : action.payload
			};
		break;

		case 'DEPOSIT-SET-ACTIVE-BUS':
			state = update(state, { 
			    bus: { 
			    	[action.payload.index] : {$set: action.payload.id}
			    }
		  	});
		break;

		case 'UPDATE-BOOKING-FIELD':
			state = update(state, { 
			    [action.payload.field]: { 
			    	[action.payload.index] : {$set: action.payload.value}
			    }
		  	});
		break;


		case 'SET-BOOKING-ACTIVE-SEAT':
			state = update(state, { 
			    seat: { 
			    	[action.payload.index] : {$set: action.payload.seat}
			    }
		  	});
		break;


		case 'SET-BOOKING-PACK':
			state = update(state, { 
			    pack: { 
			    	[action.payload.index] : {$set: action.payload.pack}
			    }
		  	});
		break;

		case 'SET-BOOKING-ADD':
			state = update(state, { 
			    add: { 
			    	[action.payload.persona] : {
			    		[action.payload.index] : {$set: action.payload.add}
			    	}
			    }
		  	});
		break;		

		case 'SET-BOOKING-COST':
			state = update(state, { 
			    cost: { 
			    	[action.payload.index] : {$set: action.payload.cost}
			    }
		  	});
		break;


		case 'SET-BOOKING-TOTAL':
			state = {
				...state,
				total : action.payload
			};
		break;		

		case 'SET-BOOKING-IMAGE':
			state = {
				...state,
				image : action.payload
			};
		break;

		case 'SET-BOOKING-SRC':
			state = {
				...state,
				src : action.payload
			};
		break;


		case 'SET-BOOKING-COMMENTS':
			state = {
				...state,
				comments : action.payload
			};
		break;


		case 'SET-BOOKING-REFERENCE':
			state = {
				...state,
				reference : action.payload
			};
		break;


		case 'SET-BOOKING-QTY':
			state = {
				...state,
				quantity : action.payload
			};
		break;		

		case 'SET-TERMS-AND-CONDITIONS':
			state = {
				...state,
				accepted : action.payload
			};
		break;	

					
		case 'SET-DEPOSIT-THANKYOU':
			state = {
				...state,
				thankyou : action.payload
			};
		break;


		case 'SET-RESUME':
			state = {
				...state,
				resume : action.payload
			};
		break;



		default:
			return state;
	}

	return state
};