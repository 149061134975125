import React from 'react';
import compose from 'recompose/compose';

// Material Ui =>
import Grid from '@material-ui/core/Grid';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Redirect, withRouter } from 'react-router-dom';

// Actions =>
import {logOut} from '../../Acceso/Actions';
import {updateWindowWidth} from '../Public/Actions';

// Components =>
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from '../Public/HeaderMobile';
import LeftNav from './LeftNav';
import Footer from '../Public/Footer';
import Terms from './Terms';
import Password from './Password';
import PasswordUpdated from './PasswordUpdated';
import Welcome from "../../Perfil/Welcome";

// Helper:
import {windowScroll} from "../../Utilities/Helpers";

/****************************************
    Return Render =>
****************************************/
class Layout extends React.Component {
   
    componentWillMount(){
        var props = this.props;
        window.onresize = function(event) {
            props.updateWindowWidth();
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        windowScroll(0,0);
    }

    render() {
        if(this.props.global.loggedout || !this.props.global.authorized)
        {
            return <Redirect push to={"/"} />
        }
        else
        {
            return (
                <div id="profile">
                    {/* HEADER */}
                    {(this.props.global.windowwidth <= 959) ? (<HeaderMobile/>) : (<HeaderDesktop/>)}

                    {/* MAIN CONTAINER */}
                    <div className="breakpoint">
                        <Grid container>
                            {/* LeftNav */}
                            {(this.props.global.windowwidth <= 991) ? (false) : (<LeftNav/>)}

                            {/* Content */}
                            <Grid item md={9} sm={12} xs={12}>
                                <main>
                                    {this.props.children}
                                </main>
                            </Grid>
                        </Grid>
                    </div>
                    
                    {/* FOOTER */}
                    <Footer/>

                    {/* Terms & Conditions */}
                    <Terms/>

                    {/* Cambio de contraseña */}
                    <Password/>

                    {/* Password actualizado */}
                    <PasswordUpdated/>

                    {/* Mensaje de bienvenida al iniciar sesión */}
                    <Welcome/>
                </div>
            )
        }
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        logOut,
        updateWindowWidth
    }, dispatch);
}

export default compose(
    withRouter,
    connect(mapStateToProps, matchDispatchToProps)
)(Layout);