import {API} from "../Utilities/Helpers";

export function resetVars() {
	return (dispatch) => {
		dispatch({
			type 	: "SET-UPCOMING-EVENTS", 
			payload	: false
		});
	};
}



// Obtiene las notificaciones del perfil del usuario:
export function getUpcoming() {
    return (dispatch, getState) => {
		const state = getState();
		const request = API(`${state.global.API}getUpcoming`, "GET");
	    request.then(res => res.json()).then(items => {
			dispatch({
				type 	: "SET-UPCOMING-EVENTS", 
				payload	: items
			});
	    })    	
    }
}



export function setToggle(index){
    return (dispatch, getState) => {
    	const state = getState();
		var toggle = state.misviajes.upcoming[index].event.toggle;
		dispatch({
			type 	: "SET-TOGGLE", 
			payload	: {"index" : index, "toggle": !toggle}
		});		
    }
}




export function setActiveBus(id){
    return (dispatch) => {
		dispatch({
			type 	: "SET-ACTIVE-BUS", 
			payload	: id
		});				
    }
}




export function closeSeatAssignedModal(){
    return (dispatch, getState) => {
		dispatch({
			type 	: "SET-SEAT-ASSIGNED-MODAL", 
			payload	: false
		});				
    }
}


export function selectSeat(event, bus, seat){
    return (dispatch, getState) => {
    	const state = getState();
		const user = state.global.user.id;
    	const request = fetch(`${state.global.API}selectSeat/${event}/${bus}/${seat}/${user}`, {headers: state.global.fetchHeaders });
	    request.then(res => res.json()).then(res => {

	    	if(res.error)
	    	{
				dispatch({
					type 	: "SNACK-BAR", 
					payload	: true
				});
				dispatch({
					type 	: "SNACK-BAR-MESSAGE", 
					payload	: res.error
				});	
	    	}
	    	else
	    	{
	    		dispatch(getUpcoming());

				dispatch({
					type 	: "SET-SEAT-ASSIGNED-MODAL", 
					payload	: true
				});	

				dispatch({
					type 	: "SET-ACTIVE-SEAT", 
					payload	: seat
				});
	    	}

	    })    	
    }
}

