import React from 'react';
import compose from 'recompose/compose';

// Redux =>
// import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Componetns =>
import Seat from './Seat';


/****************************************
    Return Render =>
****************************************/
class Bus extends React.Component {
    
    componentWillMount(){
        //alert('here');
    }

    render() {;
        const index    = this.props.index;
        const bus      = this.props.bus;
        const cssclass = (bus.id === this.props.abono.bus[index]) ? '' : 'hidden';
        var busseat;
        return (
            <div key={"row-bus-"+index+"-"+bus.id} className={"transBox "+cssclass}>
                <b>{bus.info.name}</b>
                <div className={"camion camion"+bus.info.type}>
                    <img src={bus.info.image} alt={bus.info.type}  width="791" height="190"/>
                    {bus.info.seats.map(seat => {
                        var isbooked = false;
                        bus.booked.map(b =>{
                            if(parseInt(b,10) === parseInt(seat,10))
                            { 
                                isbooked = true;
                            }
                            return true;
                        });

                        // También checamos si el asiento fue seleccionado para alguno de los otros acompañantes
                        for(var ac=1; ac<=5; ac++)
                        {
                            if(ac !== index) // <=  Ignoramos al usuario actual
                            {
                                if(this.props.abono.seat[ac] !== false)
                                {
                                    busseat = this.props.abono.seat[ac].split('-');
                                    if( (parseInt(seat,10) === parseInt(busseat[1],10)) && (parseInt(bus.id,10) === parseInt(busseat[0],10)) )
                                    {
                                        isbooked = true;
                                    }
                                }
                            }
                        }

                        // Marcamos el asiento seleccionado del cliente
                        var isactive = false;
                        busseat  = this.props.abono.seat[index];
                        if(busseat !== false)
                        {
                            var spl      = busseat.split('-');
                            if( (parseInt(seat,10) === parseInt(spl[1],10)) && (parseInt(bus.id,10) === parseInt(spl[0],10)) )
                            {
                                isactive = true;
                            }
                        }

                        return (<Seat key={"row-seat"+index+bus.id+seat} index={index} seat={seat} isbooked={isbooked} isactive={isactive}/>)
                    })}
                </div>
            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // ???
    }, dispatch);
}
}********************************************/



export default compose(
    connect(mapStateToProps, null)
)(Bus); 