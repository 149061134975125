import React from 'react';
import compose from 'recompose/compose';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {setActiveBus} from './Actions';


// Componetns =>



/****************************************
    Return Render =>
****************************************/
class Btn extends React.Component {
    render() {
        const index    = this.props.index;
        const bus      = this.props.bus;
        const cssclass = (bus.id === this.props.abono.bus[index]) ? 'active' : '';
        return (
            <button type="button" style={{marginRight: '10px' }} className={"cta gray "+cssclass} onClick={() => this.props.setActiveBus(index, bus.id) }> 
                <i style={{marginRight: '5px' }} className="fas fa-bus"></i>
                ({bus.booked.length}/{bus.info.seats.length})
            </button>
        )
    }
}



/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setActiveBus
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Btn); 