import React from 'react';
import compose from 'recompose/compose';

// Material UI =>
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {
    updateField, 
    imageLoader, 
    updateComments, 
    updateQty, 
    updateReference,
    updateTerms,
    setTravellers
} from './Actions';

// Componetns =>
import Resume from './Resume';

/****************************************
    Return Render =>
****************************************/
class Next extends React.Component {


    // Will Mount =>
    componentWillMount(){
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.abono)
        {
            this.props.setTravellers();
        }
    }


    // Image Span
    imageSpan(){
        if(this.props.abono.image)
        return false;
        else
        return(<span><i className="fa fa-camera" aria-hidden="true"></i></span>);
    }



    // Image Src
    imageSrc(){
        if(this.props.abono.image)
        return (<img src={this.props.abono.src} alt={"Imagen de abono"} />);
        else
        return false;
    }    

    
    // Area de repartición de Abono =>
    splitDepositArea(){
        if(this.props.abono.info.travellers.length > 1)
        {
            return(
                <div>
                    {/* Yellow Warning */}
                    <div className="yellow-ribbon">
                        <div className="box">
                            <p><b>Nota:</b> Favor de dividir su depósito entre las personas que van a reservar el viaje, la cantidad total a ingresar debe coincidir con su depósito o transferencia.</p>
                            <img src="/img/general/pointing-arrow-big.png" alt={"pointer"} />
                        </div>
                    </div>
                    {/* Split them all!! */}
                    <div className="split-deposit">
                        <div className="mit-forms">
                            <Grid container className="th" spacing={16}>
                                <Grid item xs={4}>Cantidad a dividir: <b>${this.props.abono.quantity}</b></Grid>
                                <Grid item xs={3}><strong>Compra total</strong></Grid>
                                <Grid item xs={3}>Asignar abono:</Grid>
                                <Grid item xs={2} align="right">Adeudo:</Grid>
                            </Grid>
                            {/* División del Depósito */}
                            {this.props.abono.info.travellers.map((traveller, index) => {
                                index++;
                                return(<Grid key={"split-deposit"+index} container className={"td"} spacing={16}>
                                    <Grid item sm={4} xs={12}>
                                        <strong>Persona {index}</strong>
                                        <em>{traveller.firstname} {traveller.lastname}</em>
                                    </Grid>
                                    <Grid item sm={3} xs={4}>
                                        <button type="button" className="cta green">${traveller.total}</button>
                                    </Grid>
                                    <Grid item sm={3} xs={4}>
                                        <input type="text"  onChange={(e) => this.props.updateField(e, 'split', index) } defaultValue={this.props.abono.split[index]} placeholder={"Cantidad:"} />
                                    </Grid>
                                    <Grid item sm={2} xs={4} align="right">
                                        <b>${traveller.adeudo}</b>
                                    </Grid>
                                </Grid>);
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    }



    render() {
        const info = this.props.abono.info;
        if(info === false)
        {
            return(
                <Grid container justify="center">
                    <Grid item xs={12} align="center">
                        <CircularProgress style={{ color: "green" }} thickness={1.5}/>
                    </Grid>
                </Grid>)
        }
        else
        {
            return (
                <div id="abono">
                    <Grid container>
                        <Grid item md={4} sm={12} xs={12}>
                            <h1>Registrar abono</h1>
                        </Grid>
                        <Grid item md={8} sm={12} xs={12} align="right">
                            <h2>{ info.event.name }</h2>
                            <b>{ info.event.period }</b>
                        </Grid>                    
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <div className="yellow-box">
                                <p>Para hacer válido tu registro, es necesario que nos hagas llegar el comprobante de tu pago así como algunos datos adicionales. 
                                Una vez enviados, <b>mar-iguanatours confirmará tu pago en un plazo no mayor a 48 horas, con lo que quedará apartado tu lugar</b>.</p>
                                <p><b>Listo, ¡a viajar!</b></p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={24}>
                        <Grid item md={8} sm={12} xs={12}>

                            {/* Step 1 */}
                            <section>
                                <h2><big>1</big> Número de reservaciones adquiridas</h2>
                                <div className="rounded-box" style={{ padding: '5px 20px'}}>
                                    <div className="mit-forms">
                                        <Grid container spacing={24}>
                                            <Grid item xs={12}>
                                                <label><strong style={{color:'green', marginRight:'10px'}}>{ this.props.abono.info.travellers.length }</strong> Reservaciones (personas)</label>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </section>

                            {/* Step 2 */}
                            <section>
                                <h2><big>2</big> Paquetes seleccionados:</h2>
                                {this.props.abono.info.travellers.map((traveller, i) => {
                                    return(<div key={"config-block-"+i} className="rounded-box" style={{ marginBottom:'30px'}}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h3>{traveller.firstname} {traveller.lastname}</h3>
                                            </Grid>
                                        </Grid>

                                        {/* Pre-Registro */}                               
                                         <div className="mit-forms">
                                            <Grid container spacing={24}>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="far fa-user"></i>Nombre:</small>
                                                        {traveller.firstname}
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="far fa-user"></i>Apellidos:</small>
                                                        {traveller.lastname}
                                                    </label>
                                                </Grid>                        
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="far fa-envelope"></i>E-mail:</small>
                                                        {traveller.email}
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="fas fa-phone"></i>Teléfono:</small>
                                                        {traveller.phone}
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="fas fa-genderless"></i>Género:</small>
                                                        {traveller.gender}
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="fas fa-info"></i>Talla de camisa:</small>
                                                        {traveller.tshirt}
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="fas fa-bus"></i>Camión:</small>
                                                        #{traveller.booking.sort_order}
                                                    </label>
                                                </Grid>
                                                <Grid item md={6} sm={6} xs={12}>
                                                    <label className="simulate-field">
                                                        <small><i className="fas fa-ticket-alt"></i>Asiento:</small>
                                                        {traveller.booking.seat}
                                                    </label>
                                                </Grid>                                                
                                            </Grid>
                                        </div>
                                        
                                        {/* Paquete Seleccionado */}
                                        <div>                                       
                                            <Grid container className="packages">
                                                <Grid item md={10} sm={10} xs={9}>
                                                    <label>{traveller.pack.name}</label>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={3} align="right"><label className="cta green">${traveller.pack.fprice}</label></Grid>                                    
                                            </Grid>
                                            {/* Adicionales */}
                                            <Grid container key={"add-title-row"+i}>
                                                <Grid item md={1} xs={false}></Grid>
                                                <Grid item md={11} xs={12}>
                                                    <h3>Adicionales seleccionados:</h3>
                                                </Grid>
                                            </Grid>
                                            {traveller.adds.map(add => {
                                                return(<Grid container key={"row-add-"+add.id}>
                                                    <Grid item md={1} sm={1} xs={false}></Grid>
                                                    <Grid item md={9} sm={9} xs={9}>
                                                        <label>{add.name}</label> 
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={3} align="right">
                                                        <label className="cta gray">${add.fprice}</label>
                                                    </Grid>
                                                </Grid>)
                                            })}
                                        </div>
                                    </div>)
                                })}
                            </section>

                            {/* $ Abono */}
                            <section>
                                <h2><big>3</big> Enviar notificación de abono:</h2>
                                <div className="rounded-box">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3>Información del abono</h3>
                                        </Grid>
                                    </Grid>
                                    <div className="mit-forms">
                                        <Grid container spacing={24}>
                                            <Grid item xs={6}>
                                                <select onChange={(e) => this.props.updateReference(e.target.value)} defaultValue={this.props.abono.reference}>
                                                    <option value="">&mdash;Tipo&mdash;</option>
                                                    <option>Deposito</option>
                                                    <option>Transferencia</option>
                                                    <option>Cheque</option>
                                                </select>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <input type="text" name="quantity" onChange={(e) => this.props.updateQty(e.target.value) } defaultValue={this.props.abono.quantity} placeholder="Cantidad:"/>
                                            </Grid>    
                                            <Grid item xs={12}>
                                                <label className="img-loader-holder">
                                                    { this.imageSpan() }
                                                    { this.imageSrc() }
                                                    <input type="file" onChange={(e) => this.props.imageLoader(e.target) } name="deposit" className="img-loader" accept="image/*"/>
                                                </label>
                                                <small>Sólo archivos: jpg, jpeg, gif ó png. Máximo 5MB</small>
                                            </Grid>   
                                            <Grid item xs={12}>
                                                <textarea name="comments" onChange={(e) => this.props.updateComments(e.target.value) } defaultValue={this.props.abono.comments} placeholder="Comentarios adicionales:" />
                                            </Grid>                                                             
                                                
                                            <Grid item xs={12} className="disclaimer">
                                                Si estás comprando una habitación doble o triple, favor de incliuir los nombres de las personas con las que te hospedarás.
                                            </Grid>                                          
                                        </Grid>
                                    </div>
                                    {/* Area de depósito dividido */}
                                    { this.splitDepositArea() }                                  
                                </div>
                            </section>
                        </Grid>

                        {/* Resume */}
                        <Grid item md={4} sm={12} xs={12}>
                            <Resume/>
                        </Grid>
                    </Grid>
                </div>
            )
        }   
    }






}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        updateField,
        imageLoader,
        updateComments,
        updateQty,
        updateReference,
        updateTerms,
        setTravellers
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Next); 