import React from 'react';
import compose from 'recompose/compose';

// Material Ui =>
import Grid from '@material-ui/core/Grid';

// Routing =>
import { NavLink } from 'react-router-dom'

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {logOut} from '../../Acceso/Actions';
import {dialogPassword} from '../../Utilities/Actions';


// Componetns =>

/****************************************
    Return Render =>
****************************************/
class HeaderDesktop extends React.Component {
    render() {
        const counters = this.props.global.counters;
        return (
            <header className="desktop">
                <div className="breakpoint">
                    <Grid container>
                        <Grid item md={3}>
                            <label><i className="far fa-user-circle"></i> Bienvenido {this.props.global.user.displayname}</label>
                        </Grid>
                        <Grid item md={9}>
                           <Grid container align="right">
                                <Grid item xs={12}>
                                    <NavLink className="mitem" to={"/perfil"} exact>
                                        <i className="far fa-bell"></i> Notificaciones 
                                        {counters.notifications > 0 ? (
                                            <span>{ counters.notifications }</span>
                                        ) : (null)}
                                    </NavLink>
                                    <NavLink className="mitem" to={"/perfil/mensajes"} exact>
                                        <i className="far fa-comment-alt"></i> 
                                        Mensajes 
                                        {counters.messages > 0 ? (
                                            <span>{ counters.messages }</span>
                                        ) : (null)}
                                    </NavLink>
                                    <button className="mitem" type="button" onClick={() => this.props.dialogPassword(true) }><i className="fas fa-unlock"></i> Cambiar contraseña</button>
                                    <button type="button" onClick={() => this.props.logOut() } className="mitem log-out"><i className="fas fa-power-off"></i> Cerrar sesión</button>
                                </Grid>
                           </Grid>
                        </Grid>                            
                    </Grid>
                </div>
            </header>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        logOut,
        dialogPassword
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(HeaderDesktop); 