export const contacto = (state = {
	thanks: false
}, action) => {
	switch(action.type)
	{
		case "SET-THANKS-CONTACT-MODAL":
			state = {
				...state,
				thanks : action.payload
			};
		break;

		default:
			return state;
	}

	return state
};