export const global = (state = {
	API :  'https://admin.mar-iguanatours.com.mx/api/',
	snackbar: false,
	snackbarmsg: '',
	user: (localStorage.getItem("user") === null) ? false : JSON.parse(localStorage.getItem("user")),
	authorized: (localStorage.getItem("token") === null) ? false : true,
	isout: false, 	  					// Cuando se intenta acceder a secciones privadas
	loggedout: false, 					// Cuando se pierde la sesión
	fetchHeaders: new Headers({ 'X-MIT-Token': localStorage.getItem("token") }),
	issubmiting: false,
	usermenu: false,
	mobilemenu: false,
	windowwidth: window.innerWidth,
	dterms: false,						// Show/Hide Dialog Terms
	terms: false,						// Temrs & Conditions
	accountactivation: 'wait',			// States para la activación del registro
	accountactivationdialog: false,
	variables: false,   				// Site vars: email, phone, whatsapp, social links, etc...
	counters: false,					// Profile counters: # messages, # notifications, # events, etc...
	password: false,					// Diálogo para cambio de password
	changePassword: false,				// true|false para permitir el cambio de contraseña.
	passwordupdated:false				// true|false Dialog de password actualizado
}, action) => {
	switch(action.type)
	{

		case "SET-USER-MENU":
			state = {
				...state,
				usermenu : action.payload
			};
		break;

		case "SET-MOBILE-MENU":
			state = {
				...state,
				mobilemenu : action.payload
			};
		break;


		case "SET-SUBMITING":
			state = {
				...state,
				issubmiting : action.payload
			};
		break;

		case "SET-AUTH":
			state = {
				...state,
				authorized : action.payload
			};
		break;

		case "SET-USER":
			state = {
				...state,
				user : action.payload
			};
		break;

		case "SNACK-BAR":
			state = {
				...state,
				snackbar : action.payload
			};
		break;

		case "SNACK-BAR-MESSAGE":
			state = {
				...state,
				snackbarmsg : action.payload
			};
		break;

		case 'IS-OUT':
			state = {
				...state,
				isout : action.payload
			};
		break;

		case 'IS-LOGGED-OUT':
			state = {
				...state,
				loggedout : action.payload
			};
		break;


		case 'SET-ACCOUNT-ACTIVATION':
			state = {
				...state,
				accountactivation : action.payload
			};
		break;

		case 'SET-ACCOUNT-ACTIVATION-DIALOG':
			state = {
				...state,
				accountactivationdialog : action.payload
			};
		break;


		case "UPDATE-WINDOW-WIDTH":
			state = {
				...state,
				windowwidth : action.payload
			};
		break;


		case "SET-DIALOG-TERMS":
			state = {
				...state,
				dterms : action.payload
			};
		break;


		case "SET-TERMS":
			state = {
				...state,
				terms : action.payload
			};
		break;


		case "SET-SITE-VARS":
			state = {
				...state,
				variables : action.payload
			};
		break;



		case "SET-COUNTERS":
			state = {
				...state,
				counters : action.payload
			};
		break;


		case "SET-DIALOG-PASSWORD":
			state = {
				...state,
				password : action.payload
			};
		break;


		case "SET-PASSWORD-CHANGE-ALLOWED":
			state = {
				...state,
				changePassword : action.payload
			};
		break


		case "SET-DIALOG-PASSWORD-UPDATED":
			state = {
				...state,
				passwordupdated : action.payload
			};
		break;

		case "SET-CURRENT-SECTION":
			state = {
				...state,
				section : action.payload
			};
		break;

		default:
			return state;
	}
	return state
};