// COnfirmación del Registro
export function getConocenos() {
    return (dispatch, getState) => {
    	const state = getState();
    	const request = fetch(`${state.global.API}getConocenos`);
	    request.then(res => res.json()).then(json => {
    		dispatch({
				type 	: "SET-CONOCENOS", 
				payload	: json
			});		
	    })    	
    }
}
