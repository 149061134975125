export const blogs = (state = {
	blogs: false,
	blogpost: false,
}, action) => {
	switch(action.type)
	{
		case "SET-BLOGS":
			state = {
				...state,
				blogs : action.payload
			};
		break;	

		case "SET-BLOG-POST":
			state = {
				...state,
				blogpost : action.payload
			};
		break;			

		default:
			return state;
	}

	return state
};