import React from 'react';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import {getProfile, updateProfile, resetVars} from './Actions';


// Componetns =>
import Updated from './Updated';
import Wrapper from '../Utilities/Wrapper';


/****************************************
    Return Render =>
****************************************/
class Profile extends React.Component {

    componentDidMount(){
        this.props.resetVars();
        this.props.getProfile();
    }


    render() {
        const profile = this.props.profile.profile;
        const vars = this.props.global.variables;
        return (
            <div id="perfil">
                <div className="breakpoint">
                    <Grid container>
                        <Grid item xs={12}>
                            <h1>Mi información</h1>
                            {profile ? (
                                <form className="mit-forms" onSubmit={(e) => {this.props.updateProfile(e); e.preventDefault();}}>

                                    {/* Información Personal */}
                                    <Grid container spacing={24} className="profile-section">
                                        <Grid item xs={12}>
                                            <h2>Información personal</h2>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Nombre:</label>
                                            <input type="text" name="firstname" defaultValue={profile.firstname}/>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Apellidos:</label>
                                            <input type="text" name="lastname" defaultValue={profile.lastname}/>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>E-mail:</label>
                                            <input type="text" name="email" defaultValue={profile.email}/>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Teléfono:</label>
                                            <input type="text" name="phone" defaultValue={profile.phone}/>
                                        </Grid>

                                        <Grid item md={3} sm={6} xs={6}>
                                            <label>Género:</label>
                                            <select name="gender" defaultValue={profile.gender}>
                                                <option defaultValue="">&mdash;seleccionar&mdash;</option>
                                                <option defaultValue="Femenino">Femenino</option>
                                                <option defaultValue="Masculino">Masculino</option>
                                            </select>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <label>Talla ({profile.tshirt})</label>
                                            <label style={{ fontSize: '12px' }}>Si requieres realizar un cambio en tu talla, por favor <a style={{ color: '#0aa550', fontSize: '14px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }} target="_blank" rel="noopener noreferrer" href={"https://wa.me/"+vars.whatsapp}>comunícate con nosotros</a></label>
                                        </Grid>
                                        {/*
                                        Commented on July 18, 2024 as per Migue Reuest
                                        <Grid item md={3} sm={6} xs={6}>
                                            <label>Talla:</label>
                                            <select name="tshirt" defaultValue={profile.tshirt}>
                                                <option defaultValue="">&mdash;seleccionar&mdash;</option>
                                                <optgroup label="Adulto">
                                                    <option defaultValue="Chica">Chica</option>
                                                    <option defaultValue="Mediana">Mediana</option>
                                                    <option defaultValue="Grande">Grande</option>
                                                    <option defaultValue="Extra Grande">Extra Grande</option>
                                                </optgroup>
                                                <optgroup label="Infantil">
                                                    <option defaultValue="Inf. Chica">Chica</option>
                                                    <option defaultValue="Inf. Mediana">Madiana</option>
                                                    <option defaultValue="Inf. Grande">Grande</option>
                                                </optgroup>
                                            </select>
                                        </Grid>
                                        */}
                                    </Grid>

                                    {/* Datos de Facturación */}
                                    <Grid container spacing={24} className="profile-section">
                                        <Grid item xs={12}>
                                            <h2>Datos de facturación</h2>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>RFC:</label>
                                            <input type="text" name="rfc" defaultValue={profile.rfc}/>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Razón Social:</label>
                                            <input type="text" name="rsocial" defaultValue={profile.rsocial}/>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Domicilio:</label>
                                            <input type="text" name="faddress"  defaultValue={profile.faddress}/>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>C.P.:</label>
                                            <input type="text" name="cp" defaultValue={profile.cp}/>
                                        </Grid>
                                    </Grid>


                                    {/* Redes sociales */}
                                    <Grid container spacing={24} className="profile-section">
                                        <Grid item xs={12}>
                                            <h2>Redes sociales</h2>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Facebook:</label>
                                            <input type="text" name="facebook" defaultValue={profile.facebook}/>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Twitter:</label>
                                            <input type="text" name="twitter" defaultValue={profile.twitter}/>
                                        </Grid>
                                    </Grid>


                                    {/* Contactos de emergencia */}
                                    <Grid container spacing={24} className="profile-section">
                                        <Grid item xs={12}>
                                            <h2>Contacto de emergencia</h2>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Nombre:</label>
                                            <input type="text" name="ename" defaultValue={profile.ename}/>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>E-mail:</label>
                                            <input type="text" name="eemail" defaultValue={profile.eemail}/>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <label>Teléfono:</label>
                                            <input type="text" name="ephone" defaultValue={profile.ephone}/>
                                        </Grid>
                                    </Grid>


                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Wrapper issubmiting={this.props.global.issubmiting}>
                                                <button className="cta green" type="submit">Actualizar mi información</button>
                                            </Wrapper>
                                        </Grid>
                                    </Grid>
                                </form>
                            ) : (
                                <Grid container justify="center">
                                    <Grid item xs={12} align={"center"}>
                                        <CircularProgress style={{ color: 'green', marginTop:'100px'}} thickness={1.8} />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div>

                {/* Modal Profile Updated */}
                <Updated/>

            </div>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        getProfile,
        updateProfile,
        resetVars
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Profile);