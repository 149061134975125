import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {closeSnack} from './Actions';

// Componetns =>

/****************************************
    Return Render =>
****************************************/
class Snack extends React.Component {
    render() {
        // const { classes } = this.props;
        return (
            <Snackbar
              anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
              open={this.props.global.snackbar}
              autoHideDuration={3500}
              onClose={() => this.props.closeSnack()}
              message={this.props.global.snackbarmsg}
              action={[<Button key="undo" color="secondary" size="small" onClick={() => this.props.closeSnack()}>CERRAR</Button>]}
            />
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        closeSnack
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Snack); 