import React from 'react';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import {handleWelcome} from './Actions';

import { NavLink } from 'react-router-dom'


/****************************************
    Return Render =>
****************************************/
class Welcome extends React.Component {

    componentDidMount(){
        if(localStorage.getItem("welcome")){
            this.props.handleWelcome();
            localStorage.removeItem("welcome");
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                fullScreen={classes}
                open={this.props.acceso.welcome}
                onClose={() => this.props.handleWelcome()}
                className="mit-dialog"
            >
                <DialogTitle>
                    Te damos la bienvenida {this.props.global.user.firstname}
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <i className="far fa-check-circle"></i>
                    <DialogContentText>
                        Has iniciado <b>corréctamente</b> tu sesión.
                        <br/>
                        ¿Qué te gustaría hacer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <NavLink to={"/"} onClick={() => this.props.handleWelcome() } className={"cta gray"}><i className="fas fa-mouse-pointer"></i> Volver al sitio</NavLink>
                    <button type="button" className={"cta green"} onClick={() => this.props.handleWelcome() }><i className="fas fa-user"></i> Seguir en mi perfil</button>
                </DialogActions>
            </Dialog>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}


/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        handleWelcome
    }, dispatch);
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps)
)(Welcome); 