export const messages = (state = {
    messages: false,
	threads: false,
	active: false,
}, action) => {
	switch(action.type)
	{
		case "SET-THREADS":
			state = {
				...state,
				threads : action.payload
			};
        break;
	
		case "SET-ACTIVE-THREAD":
			state = {
				...state,
				active : action.payload
			};
		break;
		

		case "SET-THREAD-MESSAGES":
			state = {
				...state,
				messages : action.payload
			};
		break;

		default:
			return state;
	}

	return state
};